import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { getRoleName } from "../../../../Utilities/Enums";
import PageContainer from "../../../Core/PageContainer";
import Panel from "../../../Core/Panel";
import Headerbar from "./Headerbar";
import Actions from "../../Common/Components/Actions";
import ConfirmModal from "../../../Core/Forms/ConfirmModal";
import MessageModal from "../../../Core/Forms/MessageModal";

export default function Users() {
    const navigate = useNavigate();
    const store = useSessionStore();

    const [users, setUsers] = useState(null);
    const [deleteModal, setDeleteModal] = useState({show: false, header: "", message: "", confirmAction: () => {}, onClose: () => {}});
    const [modal, setModal] = useState({show: false, header: "", message: "", onClose: () => {}});

    useEffect(() => {
        getUsers("");
    }, [])

    async function getUsers(search) {
        setUsers(null);

        let result = await store.UsersService.GetUsers(search);

        if (result && result.Success) {
            setUsers(result.Data);
        }

    }

    function confirmDelete(id) {
        setDeleteModal({
            show: true,
            header: "Confirm Delete",
            message: "Are you sure you want to delete this user?",
            confirmAction: () => {deleteUser(id)},
            onClose: () => {setDeleteModal({show: false, header: "", message: "", confirmAction: () => {}, onClose: () => {}})}
        });
    }

    async function deleteUser(id) {
        setDeleteModal({show: false, header: "", message: "", confirmAction: () => {}, onClose: () => {}});
        let result = await store.UsersService.DeleteUser(id);

        if (result && result.Success) {
            setModal({
                show: true,
                header: "Success",
                message: "The user has been deleted successfully.",
                onClose: () => {setModal({show: false, header: "", message: "", onClose: () => {}})}
            });
            
            getUsers("");
        } else {
            setModal({
                show: true,
                header: "Error",
                message: "An error occurred while deleting the user. Please try again.",
                onClose: () => {setModal({show: false, header: "", message: "", onClose: () => {}})}
            });
        }
    }

    return <PageContainer title="Admin Settings" subtitle="Users">
                <Panel>
                    <Headerbar onSearchClicked={(search) => getUsers(search)}></Headerbar>
                    <hr />
                    <Row>
                        <Col>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Surname</th>
                                        <th className="d-none d-xl-table-cell">Email Address</th>
                                        <th className="d-none d-xl-table-cell">Contact Number</th>
                                        <th className="d-none d-lg-table-cell">Role</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users &&
                                        users.map((user, index) =>
                                            <tr key={user.Id}>
                                                <td>{user.FirstName}</td>
                                                <td>{user.LastName}</td>
                                                <td className="d-none d-xl-table-cell">{user.Email}</td>
                                                <td className="d-none d-xl-table-cell">{user.ContactNumber}</td>
                                                <td className="d-none d-lg-table-cell">{getRoleName(user.Role)}</td>
                                                <td align="center">
                                                    <Actions
                                                        edit={{ action: () => navigate(`/user/${user.Id}`, {state: { action: "edit" }}), title: "Edit User" }}
                                                        view={{ action: () => navigate(`/user/${user.Id}`, {state: { action: "view" }}), title: "View User" }}
                                                        del={{ action: () => confirmDelete(user.Id) }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Panel>
                <ConfirmModal modal={deleteModal}/>
                <MessageModal modal={modal}/>
            </PageContainer>
}