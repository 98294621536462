import { useState, useEffect } from "react";
import { Col, Row, Form, Spinner } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { Roles } from "../../../../../Utilities/Enums";
import VariationAndRemediationValidationSchema from "../Validation/VariationAndRemediationValidationSchema";
import VarianceReport from "./VarianceReport";
import PageContainer from "../../../../Core/PageContainer";
import FormContainer from "../../../../Core/Forms/FormContainer";
import FormSection from "../../../../Core/Forms/FormSection";
import MessageModal from "../../../../Core/Forms/MessageModal";
import CustomerDetails from "./CustomerDetails";
import TypeOfWorks from "./TypeOfWorks";
import HoursWorked from "./HoursWorked";
import MaterialsOrderedUsed from "./MaterialsOrderedUsed";
import CompletedBySection from "../../../../Core/Forms/CompletedBySection";
import SignatureField from "../../../../Core/Forms/SignatureField";

export default function VariationAndRemediationReport() {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
	const store = useSessionStore();
    const createVariance = location?.pathname === "/JobSheets/VariationAndRemediation/Add" ? true : false;

    const form = useForm({mode: "onChange", resolver: yupResolver(VariationAndRemediationValidationSchema)});
    const { getValues, handleSubmit, formState } = form;

    const [varianceReport, setVarianceReport] = useState(null);
    const [viewOnly, setViewOnly] = useState(location.state?.action && location.state.action === "view" ? true : false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [modal, setModal] = useState({show: false, header: "", message: ""});

    useEffect(() => {
        
        if (params.id) {
            getVarianceReport(params.id);
            
        } else if (location.state?.jobSheetId) { 

            (async () => {
                let jobSheet = await getJobSheet(location.state.jobSheetId);
            
                setVarianceReport({
                    ...VarianceReport,
                    ...jobSheet
                })
            })();
        }
	}, []);

    useEffect(() => {
        if (varianceReport) form.reset(varianceReport, {keepDirtyValues: true});
        
    }, [varianceReport]);

    useEffect(() => {

        if (Object.keys(formState.errors).length === 0) {
            setSubmitEnabled(true);
        } else {
            setSubmitEnabled(false);
        }
    }, [formState]);

    const getVarianceReport = async (remediationReportId) => {

        let result = await store.RemediationReportsService.GetRemediationReport(remediationReportId);

        if (result && result.Data) {

            let formData = {
                ...result.Data.RemediationReport,
                Customer: result.Data.Customer,
                Site: result.Data.Site,
                JobSheetNumber: params.jobNumber,
                Date: result.Data.RemediationReport.Date.substring(0, 4) !== "0001" ? DateTime.fromISO(result.Data.RemediationReport.Date).toFormat("yyyy-MM-dd") : null
            };

            if (formData.SubmittedToAdminOn && store.Session.Role !== Roles.Admin) {
                setViewOnly(true);
            }

            setVarianceReport(formData);
        }
    }

    const getJobSheet = async (jobSheetId) => {

        let result = await store.JobSheetsService.GetJobSheet(jobSheetId);

        if (result && result.Data) {  

            return {
                JobSheetId: result.Data.JobSheet.Id,
                TeamLeaderId: result.Data.SalesQuote.TeamLeaderId,
                Customer: result.Data.SalesQuote.Customer,
                Site: result.Data.SalesQuote.Site,
                JobSheetNumber: `JS-EST${result.Data.JobSheet.JobSheetNumber}`
            }
        }

        return {};
    }

    const onSave = async (submit = false) => {

        const data = getValues();

        let result = null;

        let varianceData = {
            ...data
        };

        delete varianceData.Customer;
        delete varianceData.Site;

        if (varianceData.Date === null) varianceData.Date = "0001-01-01";

        for (let i = 0; i < varianceData.Materials.length; i++) {
            data.Materials[i].CostPrice = data.Materials[i].CostPrice + "";
            data.Materials[i].Quantity = data.Materials[i].Quantity + "";
        }
        
        if (createVariance) {

            result = await store.RemediationReportsService.CreateRemediationReport(varianceData);
        } else {

            result = await store.RemediationReportsService.UpdateRemediationReport(varianceData);
        }

        if (!submit) {
            if (result?.Success) {

                setModal({
                    show: true,
                    header: "Success!",
                    message: "Variance & Remediation Report saved successfully",
                    onClose: () => {onSuccess()}
                });
            } else {
                setModal({
                    show: true,
                    header: "Error!",
                    message: "An error occurred while saving the Variance & Remediation Report",
                    onClose: () => {setModal({show: false, header: "", message: ""})}
                });
            }
        }

        return result?.Data;
    }
    
    const onSubmit = async () => {

        let remediationReportId = params.id ? params.id : null;

        if (!params.id) {
            remediationReportId = await onSave(true);
        } else {
            await onSave(true);
        }

        if (!remediationReportId) return;

        let result = await store.RemediationReportsService.SubmitRemediationReport(remediationReportId);

        if (result?.Success) {
            setModal({
                show: true,
                header: "Success!",
                message: "Variance & Remediation Report submitted successfully",
                onClose: () => {onSuccess()}
            });
        } else {
            setModal({
                show: true,
                header: "Error!",
                message: result.Message,
                onClose: () => {setModal({show: false, header: "", message: ""})}
            });
        }
    }

    const onError = (errors, e) => console.log(errors, e);
    
    const onSuccess = () => {
        setModal({show: false, header: "", message: ""});
        navigate(-1);
    }

    return 	<PageContainer title="Projects" subtitle="Job Sheets - Variation & Remediation Report">
				{varianceReport ?
                    <FormContainer
                        formTitle="Variation & Remediation Report"
                        formNumber={varianceReport && varianceReport.RemediationReportNumber && `${varianceReport.JobSheetNumber}-VR${varianceReport.RemediationReportNumber}`}
                        viewOnly={viewOnly}
                    >
                        <FormProvider { ...form }>
                            <Form className="text-black" onSubmit={handleSubmit(onSubmit, onError)}>
                                <CustomerDetails date={varianceReport && varianceReport.Date} disabled={viewOnly}/>                            
                                <TypeOfWorks disabled={viewOnly}/>
                                <HoursWorked disabled={viewOnly}/>
                                <MaterialsOrderedUsed disabled={viewOnly}/>
                                <CompletedBySection   
                                    submittedToAdminOn={varianceReport?.SubmittedToAdminOn ? varianceReport.SubmittedToAdminOn : ""}
                                    onSave={onSave}
                                    submitEnabled={submitEnabled}                                    
                                    disabled={viewOnly}
                                />
                                <FormSection>
                                    <Row>
                                        <Col className="col-6 col-md-6 mb-3 mb-md-0">
                                            <SignatureField label="Customer Signature" name="CustomerSignature" type="textarea"/>
                                        </Col>
                                        <Col className="col-6 col-md-6">
                                            <SignatureField label="Date" name="Date" />
                                        </Col>
                                    </Row>
                                </FormSection>
                            </Form>
                        </FormProvider>
                    </FormContainer> :
                    <div className="text-center pt-5 mt-5">
                        <Spinner variant="light"></Spinner>
                    </div>
                }
                <MessageModal modal={modal}/>
			</PageContainer>
}