import { useEffect, useState } from 'react';
import { Form, Row, Col, Image, Stack } from 'react-bootstrap';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";
import FormSection from '../../../Core/Forms/FormSection';
import PlusButton from '../../../Core/Forms/PlusButton';
import MinusButton from '../../../Core/Forms/MinusButton';

export default function Photos ({disabled}) {
    const { control, watch, trigger, register, getValues, formState: { errors } } = useFormContext();
    const { fields, append, remove, update } = useFieldArray({
        control: control,
        name: "Photos",
    });
    const watchField = watch("Photos");

    const [photoRows, setPhotoRows] = useState([]);

    useEffect(() => {
        if (getValues("Photos") && getValues("Photos").length === 0) {
            
            append([
                {
                    Id: null,
                    Base64Data: "",
                    FileName: "",
                    MimeType: ""
                },
                {
                    Id: null,
                    Base64Data: "",
                    FileName: "",
                    MimeType: ""
                },
                {
                    Id: null,
                    Base64Data: "",
                    FileName: "",
                    MimeType: ""
                }
            ])            
            return;
        }

        let count = getValues("Photos") && getValues("Photos").length % 3;
        
        if (count > 0) {
            
            for (let i = 0; i < 3 - count; i++) {
                
                append({
                    Id: null,
                    Base64Data: "",
                    FileName: "",
                    MimeType: ""
                });
            };
        } else {
            
            if (!getValues("Photos")) return;

            let rows = [];
            let count = 0;

            for (let i = 0; i < getValues("Photos").length / 3; i++) {
                
                rows.push(PhotoRow({index: count}));
                count += 3;
            }

            setPhotoRows(rows);
        }
    }, [watchField]);

    const addFile = (event, index) => {

        let files = event.target.files;
        let photo = {...fields[index]};

        if (files.length > 0) {

            let file = files[0];

            photo.FileName = file.name;
            photo.MimeType = file.type;
            delete photo.id;

            if (file.size <= 2 * 1024 * 1024) {

                let reader = new FileReader();
    
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    
                    register(`Photos.${index}.Base64Data`);

                    photo.Base64Data = e.target.result.split(",")[1];

                    update(index, photo);

                    trigger(`Photos.${index}.Base64Data`);
                }
            } else {
                
                if (index >= 0 ) {

                    update(index, {
                        ...photo,
                        Base64Data: null
                    });

                    trigger(`Photos.${index}.Base64Data`);
                }
            }
        }
    }

    const removeRow = () => {
        remove([-1]);
        remove([-1]);
        remove([-1]);
        setPhotoRows(photoRows.slice(0, -1));
    }

    const PhotoRow = ({index}) => {
        return (
            <Row>
                <Col className="col-12 col-md-4">
                    {!disabled && 
                        <Form.Group controlId="formFile" className="mb-3 d-print-none">
                            <Form.Control name={`Photos`} type="file" onChange={(event) => {addFile(event, index)}} disabled={disabled}/>
                            <ErrorMessage
                                errors={errors}
                                name={`Photos.${index}.Base64Data`}
                                render={({ message }) => <Form.Text className="text-danger">{ message }</Form.Text>}
                            />
                        </Form.Group>
                    }
                    <Col className="col-12 mb-3 mb-md-0">
                        {watchField && getValues(`Photos.${index}.Base64Data`)?.length > 0 &&
                            <Image { ...register(`Photos.${index}.Base64Data`) } src={"data:" + getValues(`Photos.${index}.MimeType`) + ";base64," + getValues(`Photos.${index}.Base64Data`)} rounded thumbnail/>
                        }
                    </Col> 
                </Col>
                <Col className="col-12 col-md-4">
                    {!disabled && 
                        <Form.Group controlId="formFile" className="mb-3  d-print-none">
                            <Form.Control name={`Photos`} type="file" onChange={(event) => {addFile(event, index + 1)}} disabled={disabled}/>
                            <ErrorMessage
                                errors={errors}
                                name={`Photos.${index + 1}.Base64Data`}
                                render={({ message }) => <Form.Text className="text-danger">{ message }</Form.Text>}
                            />
                        </Form.Group>
                    }
                    <Col className="col-12 mb-3 mb-md-0">
                        {watchField && getValues(`Photos.${index + 1}.Base64Data`)?.length > 0 &&
                            <Image { ...register(`Photos.${index + 1}.Base64Data`) } src={"data:" + getValues(`Photos.${index + 1}.MimeType`) + ";base64," + getValues(`Photos.${index + 1}.Base64Data`)} rounded thumbnail/>
                        }
                    </Col>
                </Col>
                <Col className="col-12 col-md-4">
                    {!disabled && 
                        <Form.Group controlId="formFile" className="mb-3  d-print-none">
                            <Form.Control name={`Photos`} type="file" onChange={(event) => {addFile(event, index + 2)}} disabled={disabled}/>
                            <ErrorMessage
                                errors={errors}
                                name={`Photos.${index + 2}.Base64Data`}
                                render={({ message }) => <Form.Text className="text-danger">{ message }</Form.Text>}
                            />
                        </Form.Group>
                    }
                    <Col className="col-12">
                        {watchField && getValues(`Photos.${index + 2}.Base64Data`)?.length > 0 &&
                            <Image { ...register(`Photos.${index + 2}.Base64Data`) } src={"data:" + getValues(`Photos.${index + 2}.MimeType`) + ";base64," + getValues(`Photos.${index + 2}.Base64Data`)} rounded thumbnail/>
                        }
                    </Col>    
                </Col>
            </Row>
        )
    }

    return (
        <FormSection title="Photos">
            <Row>
                <Row>
                    <p className="">Photos (limit 2MB)</p>
                </Row>
                <Stack gap={3}>
                    { photoRows }
                    {!disabled && 
                <Row>
                    <Col className="col-6 d-flex justify-content-start">
                        <Stack direction="horizontal">
                            <PlusButton                                        
                                onClick={
                                    () => {
                                        append({
                                            Id: null,
                                            Base64Data: "",
                                            FileName: "",
                                            MimeType: "" 
                                        })
                                    }
                                }
                                disabled={disabled}
                            />
                            {
                                fields.length > 1 &&
                                <MinusButton onClick={() => {removeRow()}} disabled={disabled}/>
                            }
                        </Stack>
                    </Col>
                </Row>
            }            
                </Stack>
            </Row>
        </FormSection>
    )
}