import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles/Style.scss";

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Components/App';
import { SessionStoreProvider } from './Stores/SessionStore';

fetch("./config.json").then(async (response) => {
	if(response.ok) {
		let config = await response.json();

		const root = ReactDOM.createRoot(document.getElementById('root'));

		root.render(
			<React.StrictMode>
				<SessionStoreProvider config={ config }>
					<App></App>
				</SessionStoreProvider>
			</React.StrictMode>
		);
	}
});