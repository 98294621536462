import { useEffect, useState } from "react";
import { useFormContext } from 'react-hook-form';
import { Col, Row, Stack } from "react-bootstrap";
import InputField from "../../../Core/Forms/InputField";
import SelectField from "../../../Core/Forms/SelectField";
import NumberField from "../../../Core/Forms/NumberField";
import CurrencyField from "../../../Core/Forms/CurrencyField";
import { TaskOptions, getTaskOptionsInformation } from "../../../../Utilities/Enums";
import PaddedCol from "../../../Core/PaddedCol";

export default function JobDescription ({disabled, index, updateJobTotal, updateTotal, isSalesQuote, jobDescription}) {

    const { getValues, setValue } = useFormContext();
    const [loading, setLoading] = useState(true);

    const taskName = `JobDescriptions.${index}.Task`;
    const squaresName = `JobDescriptions.${index}.Squares`;
    const linealMetersName = `JobDescriptions.${index}.LinealMeters`;
    const unitCostName = `JobDescriptions.${index}.UnitCost`;
    const lineTotalName = `JobDescriptions.${index}.LineTotal`;
    const additionalInfoName = `JobDescriptions[${index}].AdditionalInformation`;
    
    useEffect(() => {

        calculateLineTotal(null);
        updateTotal();
    }, []);

    useEffect(() => {

        calculateLineTotal(null);
    }, [getValues(unitCostName)]);

    useEffect(() => {

        calculateLineTotal(null);
    }, [getValues(lineTotalName)]);

    useEffect(() => {
        
        if (!loading) {
            setValue(additionalInfoName, getTaskOptionsInformation(getValues(taskName)));
        }
        
        setLoading(false);

    }, [getValues(taskName)]);

    const calculateLineTotal = (value) => {
        
        if (value === null) {
            value = parseFloat(getValues(squaresName));

            if (isNaN(value)) {
                value = parseFloat(getValues(linealMetersName));
            }
        }

        value = parseFloat(value);
        
        let unitCost = getValues(unitCostName);
        
        if (unitCost && typeof unitCost !== "string") unitCost = unitCost.toString();

        let cost = parseFloat(unitCost);
        
        if (value > 0 && cost > 0) {

            const LineTotal = (value * cost).toFixed(2);
            
            setValue(lineTotalName, LineTotal);
            
            updateJobTotal(index, LineTotal);
        } else {

            updateJobTotal(index, "0");
        }
    }

    return  <Stack gap={3}>
                <Row className="ps-2 me-0">
                    <PaddedCol className={`col-12  mb-3 mb-xxl-0 ${isSalesQuote ? "col-xxl-4" : "col-xxl-6 mb-3 mb-xxl-0"}`}>
                        <SelectField
                            name={taskName}
                            label="Task"
                            options={TaskOptions}
                            {...(disabled && ({ disabled }))}
                        />
                    </PaddedCol>
                    <PaddedCol className={isSalesQuote ? "col-6 col-xxl-2" : "col-6 col-xxl-3"}>
                        <NumberField
                            name={squaresName}
                            label="Squares"
                            textAlignRight
                            decimalPlaces={2}
                            onChange={(value) => {
                                                    setValue(squaresName, value);
                                                    calculateLineTotal(value);
                                                }}
                            disabled={disabled || (getValues(linealMetersName) !== "" && getValues(linealMetersName) !== null)}
                        />
                    </PaddedCol>
                    <PaddedCol className={isSalesQuote ? "col-6 col-xxl-2" : "col-6 col-xxl-3"}>
                        <NumberField
                            name={linealMetersName}
                            label="Lineal Meters"
                            textAlignRight
                            decimalPlaces={2}
                            onChange={(value) => {
                                                    setValue(linealMetersName, value);
                                                    calculateLineTotal(value);
                                                }}
                            disabled={disabled || (getValues(squaresName) !== "" && getValues(squaresName) !== null)}
                        />
                    </PaddedCol>
                    {
                        isSalesQuote &&
                            <>
                                <PaddedCol>
                                    <CurrencyField
                                        name={unitCostName}
                                        label="Unit Cost"
                                        textAlignRight
                                        decimalPlaces={2}
                                        onChange={(value) => {
                                                    setValue(unitCostName, value);
                                                    calculateLineTotal(null);
                                                }}
                                        {...(disabled && ({ disabled }))}
                                    />
                                </PaddedCol>
                                <PaddedCol>
                                    <CurrencyField
                                        name={lineTotalName}
                                        label="Line Total"
                                        textAlignRight
                                        decimalPlaces={2}
                                        disabled
                                    />
                                </PaddedCol>
                            </>
                    }
                </Row>
                <InputField name={`JobDescriptions[${index}].AdditionalInformation`} type="textarea" rows={7} {...(disabled && ({ disabled }))}/>
            </Stack>
}