import { Form } from "react-bootstrap";
import { useFormContext, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NumericFormat } from "react-number-format";

export default function NumberField({label, labelClass, value, name, textAlignRight, decimalPlaces, onChange, disabled, required }) {

    const { control, getValues, watch, formState: { errors } } = useFormContext();

    const watchField = watch(name);

    let className = textAlignRight ? "text-end" : "";

    return (
        <Form.Group>
            {label &&
                <Form.Label className={labelClass ? labelClass : ""}>{ label }</Form.Label>
            }
            <Controller
                name={name}
                control={control}
                rules={{
                    required: required,
                }}
                value={value}
                render={({ field }) => {
                    return (
                        <NumericFormat
                            thousandSeparator=","
                            decimalScale={decimalPlaces}
                            fixedDecimalScale={true}
                            customInput={ Form.Control }
                            value={getValues(name)}
                            name={field.name}
                            className={className + (required && !watchField ? "border-danger" : "")}
                            onValueChange = {(value) => {
                                onChange && onChange(value.floatValue);
                                field.onChange(value.floatValue);
                            }}
                            disabled={disabled}
                            required={required}
                        />
                    )}
                }
            />
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => <Form.Text className="text-danger">{ message }</Form.Text>}
            />
        </Form.Group>
    );
}