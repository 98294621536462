import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { DateTime } from "luxon";

export default function DateField({ name, label, value, type, className, required, disabled }) {
	const { register, watch, formState: { errors } } = useFormContext();
	let watchField = watch(name);

    const [date, setDate] = useState("");

    useEffect(() => {
        if (value) {
			if (value && value.substring(0, 10) === "0001-01-01") {
				
				setDate(null);
			} else {
				if (type && type === "time") {
					setDate(DateTime.fromISO(value).toFormat("HH:mm"));
				} else {
					setDate(DateTime.fromISO(value).toFormat("yyyy-MM-dd"));
				}
			}
        }
    }, [value]);

	return 	<Form.Group {...(className && ({ className }))}>
				
				{label && 	
					<Form.Label>{ label }</Form.Label>
				}
					<Form.Control
						type={type ? type : "date"}
						disabled={disabled}
						className={(required && watchField === "" ? "border-danger" : "")}
						{ ...register(name, { required: required, onChange: (e) => {setDate(e.target.value)}}) }
						{...(date && ({ value: date }))}
						required={required}
					/>
					<ErrorMessage
						errors={errors}
						name={name}
						render={({ message }) => <Form.Text className="text-danger">{ message }</Form.Text>}
					/>
			</Form.Group>
}