import { Col, Row, Stack, Form } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import InputField from "../../../../Core/Forms/InputField";
import NumberField from "../../../../Core/Forms/NumberField";
import CurrencyField from "../../../../Core/Forms/CurrencyField";
import FormSection from "../../../../Core/Forms/FormSection";
import PlusButton from "../../../../Core/Forms/PlusButton";
import MinusButton from "../../../../Core/Forms/MinusButton";
import PaddedCol from "../../../../Core/PaddedCol";

const MaterialsOrderedUsed = ({ disabled }) => {

    const { control, getValues } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control: control,
        name: "Materials",
    });

    return (
    <FormSection title="Materials Ordered/Used">        
        <Stack gap={3}>
            {fields.map((mou, index) =>
                <Row key={`MaterialsOrderedUsed-${index}`} className="ps-2 me-0">
                    <PaddedCol className="col-6 col-xxl-2 mb-3 mb-md-0">
                        <InputField label="Order Number" labelClass={index > 0 ? "d-block d-xxl-none" : ""} name={`Materials[${index}].OrderNumber`} disabled={disabled}/>
                    </PaddedCol>
                    <PaddedCol className="col-6 col-xxl mb-3 mb-xxl-0">
                        <InputField label="Supplier" labelClass={index > 0 ? "d-block d-xxl-none" : ""} name={`Materials[${index}].Supplier`} disabled={disabled}/>
                    </PaddedCol>
                    <PaddedCol className="col-12 col-xxl-4 mb-3 mb-xxl-0">
                        <InputField label="Description of Materials" labelClass={index > 0 ? "d-block d-xxl-none" : ""} name={`Materials[${index}].Description`} disabled={disabled}/>
                    </PaddedCol>
                    <PaddedCol className="col-6 col-xxl-2">
                        <NumberField
                            label="Quantity"
                            labelClass={index > 0 ? "d-block d-xxl-none" : ""}
                            name={`Materials[${index}].Quantity`}
                            value={getValues(`Materials[${index}].Quantity`)}
                            decimalPlaces={2}
                            disabled={disabled}
                        />
                    </PaddedCol>
                    <PaddedCol className="col-6 col-xxl-2">
                        <CurrencyField
                            label="Cost Price"
                            labelClass={index > 0 ? "d-block d-xxl-none" : ""}
                            name={`Materials[${index}].CostPrice`}
                            value={getValues(`Materials[${index}].CostPrice`)}
                            textAlignRight
                            decimalPlaces={2}
                            disabled={disabled}
                        />
                    </PaddedCol>
                </Row>
            )}
            {!disabled &&
                <Row>
                    <Col className="col-6 d-flex justify-content-start">
                        <Stack direction="horizontal">
                        <PlusButton
                            onClick={
                                () => {
                                    append({
                                        RemediationReportId: null,
                                        Id: null,
                                        CostPrice: "",
                                        Description: "",
                                        OrderNumber: "",
                                        Quantity: "",
                                        Supplier: ""
                                    })
                                }
                            }
                            disabled={disabled}
                        />
                        {
                            fields.length > 1 &&
                            <MinusButton onClick={() => {remove(-1)}} disabled={disabled}/>
                        }
                        </Stack>
                    </Col>
                </Row>
            }
        </Stack>
    </FormSection>
    )
}

export default MaterialsOrderedUsed;