import { Stack, Row } from 'react-bootstrap';
import FormSection from "../../../../Core/Forms/FormSection";
import InputField from "../../../../Core/Forms/InputField";

const Prevention = ({ disabled }) => {

    return (
        <FormSection title="Prevention">
            <Stack gap={3}>
                <Stack gap={3}>
                    <Row>
                        <InputField                                        
                            label="What action has, or will be taken to prevent another accident of this nature happening again?"
                            name="IncidentInformation.ActionsToPreventAccidentReoccurring"
                            type="textarea"
                            rows={8}
                            disabled={disabled}
                        />
                    </Row>
                    <Row>
                        <InputField                                        
                            label="What can be done to eliminate or minimize this hazard?"
                            name="IncidentInformation.StepsToMinimizeHazard"
                            type="textarea"
                            rows={5}
                            disabled={disabled}
                        />
                    </Row>
                    <Row>
                        <InputField                                        
                            label="Additional comments"
                            name="IncidentInformation.AdditionalComments"
                            type="textarea"
                            rows={3}
                            disabled={disabled}                                        
                        />
                    </Row>
                </Stack>
            </Stack>
        </FormSection>
    );
}

export default Prevention;