import { useFieldArray, useFormContext } from 'react-hook-form';
import FormSection from '../../../../Core/Forms/FormSection';
import { Row, Col, Stack, Form } from 'react-bootstrap';
import InputField from '../../../../Core/Forms/InputField';
import PlusButton from '../../../../Core/Forms/PlusButton';
import MinusButton from '../../../../Core/Forms/MinusButton';
import PaddedCol from '../../../../Core/PaddedCol';

const JobProductDetails = ({ isJobSheet, disabled }) => {

    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control: control,
        name: "JobProductDetails",
    });
    
     return (
        <FormSection title="Job Product Details (Numbers below relate to costings and product workings)">            
            <Stack gap={3}>                
                <Row className="ps-2 me-0">
                    <Stack gap={3}>
                        {fields.map((jobProduct, index) =>                            
                            <Row key={`JobProductDetails-${index}`}>
                                <PaddedCol className="col-8 col-xxl-3 mb-3 mb-xxl-0 ">
                                    <InputField                                        
                                        label="Supplier"
                                        labelClass={index > 0 ? "d-block d-xxl-none" : ""}
                                        name={`JobProductDetails.${index}.Supplier`}
                                        disabled={disabled || isJobSheet ? true : false}
                                    />
                                </PaddedCol>
                                <PaddedCol className="col-4 col-xxl-2 mb-2 mb-xxl-0">
                                    <InputField
                                        label="Quantity"
                                        labelClass={index > 0 ? "d-block d-xxl-none" : ""}
                                        name={`JobProductDetails.${index}.Quantity`}
                                        disabled={disabled || isJobSheet ? true : false}
                                    />
                                </PaddedCol>
                                <PaddedCol className="col-12 col-xxl-3 mb-2 mb-xxl-0">
                                    <InputField
                                        label="Mix Ratio"
                                        labelClass={index > 0 ? "d-block d-xxl-none" : ""}
                                        name={`JobProductDetails.${index}.MixRatio`}
                                        disabled={disabled || isJobSheet ? true : false}
                                    />
                                </PaddedCol>
                                <PaddedCol className="col-6 col-xxl-2">
                                    <InputField
                                        label="Coverage"
                                        labelClass={index > 0 ? "d-block d-xxl-none" : ""}
                                        name={`JobProductDetails.${index}.Coverage`}
                                        disabled={disabled || isJobSheet ? true : false}
                                    />
                                </PaddedCol>
                                <PaddedCol className="col-6 col-xxl-2">
                                    <InputField
                                        label="Colour"
                                        labelClass={index > 0 ? "d-block d-xxl-none" : ""}
                                        name={`JobProductDetails.${index}.Colour`}
                                        disabled={disabled || isJobSheet ? true : false}
                                    />
                                </PaddedCol>
                            </Row>
                        )}
                    </Stack>
                </Row>
                {
                    !disabled && !isJobSheet &&
                        <Row>
                            <Col className="col-6 d-flex justify-content-start">
                                <Stack direction="horizontal">
                                    <PlusButton
                                        onClick={
                                            () => {
                                                append({
                                                    Id: null,
                                                    Supplier: "",
                                                    Quantity: "",
                                                    MixRatio: "",
                                                    Coverage: "",
                                                    Colour: ""
                                                })
                                            }
                                        }
                                        disabled={disabled}
                                    />
                                    {
                                        fields.length > 1 &&
                                        <MinusButton onClick={() => {remove(-1)}} disabled={disabled}/>
                                    }
                                </Stack>
                            </Col>
                        </Row>
                }
                {isJobSheet &&
                    <InputField                        
                        label="Additional Notes"
                        name="JobSheet.AdditionalNotes"
                        type="textarea" {...(disabled && ({ disabled }))}
                    />
                }
            </Stack>
        </FormSection>
     )
}

export default JobProductDetails;