import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

export default function MessageModal({modal, ...props}) {	

    return (
        <Modal
            {...props}
            show={modal.show}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {modal.header}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-4 pb-2">
                <p className="text-center">
                    {modal.message}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modal.onClose}>Ok</Button>
            </Modal.Footer>
        </Modal>
    )
}
