import { Stack, Col, Row, Form } from "react-bootstrap";
import { useFormContext, useFieldArray } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import FormSection from "../../../../Core/Forms/FormSection";
import { getVehiclePart } from "../../../../../Utilities/Enums";
import InputField from "../../../../Core/Forms/InputField";

const VehiclePartsGroup = ({ label, startIndex, endIndex, additionalComments, disabled }) => {
    const { control, getValues, register, formState: { errors } } = useFormContext();

    const { fields } = useFieldArray({
        control: control,
        name: "Items"
    });

    return (
        <FormSection title={label}>
            <Stack gap={3}>            
                <Stack gap={2}>
                    {
                        fields.slice(startIndex, endIndex).map((item, index) => {
                            let itemIndex = startIndex + index;
                            return ( 
                                <Row
                                    key={`VehiclePartCheckbox-${itemIndex}`}
                                    className="border-bottom pb-3 pb-xl-0"
                                >
                                    <Col className="col-12 col-xl-6">
                                        <Form.Label>{getVehiclePart(getValues(`Items.${itemIndex}.VehiclePartId`))}</Form.Label>
                                    </Col>
                                    <Col className="col-12 col-xl-6">
                                        <Row className="">
                                            <Col className="col-4 d-flex justify-content-center">
                                                <Form.Check                                        
                                                    type="radio"
                                                    label="Satisfactory"
                                                    value={0}
                                                    {...(disabled && ({ disabled }))}
                                                    { ...register(`Items.${itemIndex}.Result`) }
                                                />
                                            </Col>
                                            <Col className="col-4 d-flex justify-content-center">
                                                <Form.Check
                                                    type="radio"
                                                    label="Fail"
                                                    value={1}
                                                    {...(disabled && ({ disabled }))}
                                                    { ...register(`Items.${itemIndex}.Result`) }
                                                />
                                            </Col>
                                            <Col className="col-4 d-flex justify-content-center">
                                                <Form.Check                                        
                                                    type="radio"
                                                    label="N/A"
                                                    value={2}
                                                    {...(disabled && ({ disabled }))}
                                                    { ...register(`Items.${itemIndex}.Result`) }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>                                
                                </Row>
                            )
                        })
                    }
                </Stack>
                <ErrorMessage
                    errors={errors}
                    name={"Items"}
                    render={({ message }) => <Form.Text className="text-danger">{ message }</Form.Text>}
                />
                {additionalComments &&
                    <InputField name="AdditionalComments" type="textarea" label="Additional Comments"/>
                }
            </Stack>
        </FormSection>
    );
}

export default VehiclePartsGroup;