import React from 'react';
import { Row } from 'react-bootstrap';

export default function FormSection({ children, title }) {
    
    return  <>
                {title && 
                    <Row className="px-4 pt-2">
                        <p className="fw-bold fs-5">{title}</p>
                    </Row> 
                }
                <Row className="px-4 py-2">
                    { children }
                </Row>                   
                <hr />
            </> 
}