import React from 'react';
import { Col, Stack } from 'react-bootstrap';

export default function FormHeader({ formTitle, formNumber }) {
    
    return  <Stack className="bg-secondary rounded-1 pt-3 pb-2 px-4" direction="horizontal">
                <Col>
                    <h5>{formTitle}</h5>
                </Col>
                <Col>
                    <h5 className="text-end">{formNumber}</h5>
                </Col>
            </Stack>
}