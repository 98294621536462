import { useSessionStore } from "../Stores/SessionStore";
import Main from "./Pages/Main";
import SignIn from "./Pages/Authentication/SignIn";
import { observer } from "mobx-react-lite";

const App = observer(function App() {
	const store = useSessionStore();

	if(store.Session) {
		return <Main/>
	} else {
		return <SignIn />
	}
});

export default App;