const QA = {
	Id: null,
	TeamLeaderId: null,
	SalesQuoteId: "",
	QAFormNumber: "",
	PS3FormName: "",
	PS3FormBase64: "",
	PS3FormMimeType: "",
	DescriptionOfWorks: "",
	DateAndItemsChecked: "",
	JobSheetId: "",
	JobTasksCompleted: "",
	SubmittedToAdminOn: null,
	ProductsRequired: "",
	Results: "",
	Date: null,
	Photos: [
		{
			Id: null,
			Base64Data: "",
			FileName: "",
			MimeType: "" 
		}
    ]
}

export default QA;