import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { useSessionStore } from "../../Stores/SessionStore";
import { Roles } from "../../Utilities/Enums";
import SalesQuotes from "./Projects/SalesQuotes/Index/SalesQuotes";
import SalesQuote from "./Projects/SalesQuotes/Details/SalesQuote";
import JobSheets from "./Projects/JobSheets/Index/JobSheets";
import JobSheet from "./Projects/JobSheets/Details/JobSheet";
import QualityAssuranceForm from "./Projects/JobSheets/Details/QualityAssuranceForm";
import PrestartForm from "./Projects/JobSheets/Details/PrestartForm";
import VariationAndRemediationReport from "./Projects/JobSheets/Details/VariationAndRemediationReport";
import UserDetails from "./Users/Details/UserDetails";
import AccidentsAndIncidents from "./HealthAndSafety/AccidentsIncidents/Index/AccidentsIncidents";
import AccidentAndIncident from "./HealthAndSafety/AccidentsIncidents/Details/AccidentAndIncident";
import VehicleInspections from "./HealthAndSafety/VehicleInspections/Index/VehicleInspections";
import VehicleInspection from "./HealthAndSafety/VehicleInspections/Details/VehicleInspection";
import Users from "./Users/Users/Users";

export default function Main() {
	const store = useSessionStore();

	function getDefaultRoute() {
		if(store.Session.Role === Roles.SalesRep || store.Session.Role === Roles.Admin) {
			return "/SalesQuotes";
		}

		return "/JobSheets";
	}
	
	return <HashRouter>
				<Routes>
					{
						store.Session.Role !== Roles.TeamLeader &&
						<>
							<Route path="/SalesQuotes" element={ <SalesQuotes /> }></Route>
							<Route path="/SalesQuotes/Add" element={ <SalesQuote /> }></Route>
							<Route path="/SalesQuotes/:id?" element={ <SalesQuote /> }></Route>
						</>
					}
					{
						store.Session.Role !== Roles.SalesRep &&
						<>
							<Route path="/JobSheets" element={ <JobSheets /> }></Route>
							<Route path="/JobSheets/:id?" element={ <JobSheet /> }></Route>
							<Route path="/JobSheets/Prestart/:id?/:jobNumber?" element={ <PrestartForm /> }></Route>
							<Route path="/JobSheets/Prestart/Add" element={ <PrestartForm /> }></Route>
							<Route path="/JobSheets/QualityAssurance/:id?/:jobNumber?" element={ <QualityAssuranceForm /> }></Route>
							<Route path="/JobSheets/QualityAssurance/Add" element={ <QualityAssuranceForm /> }></Route>
							<Route path="/JobSheets/VariationAndRemediation/:id?/:jobNumber?" element={ <VariationAndRemediationReport /> }></Route>
							<Route path="/JobSheets/VariationAndRemediation/Add" element={ <VariationAndRemediationReport /> }></Route>
						</>
					}
						<Route path="/AccidentsIncidents" element={ <AccidentsAndIncidents /> }></Route>
						<Route path="/AccidentsIncidents/Add" element={ <AccidentAndIncident /> }></Route>
						<Route path="/AccidentsIncidents/:id?" element={ <AccidentAndIncident /> }></Route>
						<Route path="/VehicleInspections" element={ <VehicleInspections /> }></Route>
						<Route path="/VehicleInspections/Add" element={ <VehicleInspection /> }></Route>
						<Route path="/VehicleInspections/:id?" element={ <VehicleInspection /> }></Route>
					{
						store.Session.Role === Roles.Admin &&
						<>
							<Route path="/Users" element={ <Users /> }></Route>
							<Route path="/User/:id?" element={ <UserDetails /> }></Route>
						</>
					}
					<Route path="*" element={ <Navigate to={ getDefaultRoute() } /> }></Route>
				</Routes>
			</HashRouter> 
}