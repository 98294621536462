import { Col, Row, Stack } from 'react-bootstrap';
import FormSection from "../../../../Core/Forms/FormSection";
import InputField from "../../../../Core/Forms/InputField";
import DateField from "../../../../Core/Forms/DateField";

const CustomerDetails = ({ date, disabled }) => {

    return (
        <FormSection title="Customer Details">
            <Stack gap={3}>
                <Row>
                    <Col className="col-12 col-md-6">
                        <InputField label="Customer" name="Customer.Name" disabled required/>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 col-md-6 mb-3 mb-md-0">
                        <Stack gap={3}>
                            <InputField label="Primary Contact Person First Name" name="Customer.PrimaryContactFirstName" disabled required/>
                            <InputField label="Phone Number" name="Customer.PhoneNumber" disabled required/>
                        </Stack>
                    </Col>
                    <Col>
                        <Stack gap={3}>
                            <InputField label="Primary Contact Person Last Name" name="Customer.PrimaryContactLastName" disabled required/>
                            <InputField label="Email" name="Customer.EmailAddress" disabled required/>
                        </Stack>
                    </Col>
                </Row>
                <Stack>
                    <Row >
                        <p className="fs-6">Site Address</p>
                    </Row>
                    <Row >
                        <Col className="col-12 col-md-6 mb-3 mb-md-0">
                            <InputField label="Address Line 1" name="Site.AddressLine1" value="" disabled/>
                        </Col>
                        <Col className="col-12 col-md-6">
                            <InputField label="Address Line 2" name="Site.AddressLine2" value="" disabled/>
                        </Col>
                    </Row>
                </Stack>
                <Row>
                    <Col>
                        <InputField label="Job Sheet No." name="JobSheetNumber" value="" disabled/>
                    </Col>
                    <Col>                                    
                        <DateField label={"Date"} value={date && date} name="Date" disabled={disabled}/>
                    </Col>
                </Row>
            </Stack>
            
        </FormSection>
    )
}

export default CustomerDetails;