import { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useForm, FormProvider,  } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { Roles } from "../../../../../Utilities/Enums";
import VehicleInspectionValidationSchema from "../Validation/VehicleInspectionValidationSchema";
import PageContainer from "../../../../Core/PageContainer";
import FormContainer from "../../../../Core/Forms/FormContainer";
import DriverAndVehicleInformation from "./DriverAndVehicleInformation";
import CompletedBySection from "../../../../Core/Forms/CompletedBySection";
import MessageModal from "../../../../Core/Forms/MessageModal";
import Inspection from "./Inspection";
import VehiclePartsGroup from "./VehiclePartsGroup";

export default function VehicleInspection() {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const store = useSessionStore();
    const createInspection = location?.pathname === "/VehicleInspections/Add" ? true : false;

    const form = useForm({ mode: "onChange", resolver: yupResolver(VehicleInspectionValidationSchema) });
    const { setValue, getValues, handleSubmit, formState, register } = form;

    const [viewOnly, setViewOnly] = useState(location.state?.action && location.state.action === "view" ? true : false);        
    const [inspection, setInspection] = useState(null);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [modal, setModal] = useState({show: false, header: "", message: ""});

    useEffect(() => {

        if (params.id) {

            getInspection(params.id);
        } else if (createInspection) {

            setInspection(Inspection);
        }
    }, []);

    useEffect(() => {

        if (Object.keys(formState.errors).length === 0) {
            setSubmitEnabled(true);
        } else {
            setSubmitEnabled(false);
        }
    }, [formState]);

    useEffect(() => {

        if (inspection) {

            form.reset(inspection, {keepDirtyValues: true});

            const newItems = inspection.Items.sort((a, b) => a.VehiclePartId - b.VehiclePartId);

            for (let i = 0; i < newItems.length; i++) {
                
                setValue(`Items.${i}.VehiclePartId`, newItems[i].VehiclePartId);
                setValue(`Items.${i}.Id`, newItems[i].Id);
                setValue(`Items.${i}.Result`, newItems[i].Result + "");
            }
        }
    }, [inspection]);

    const getInspection = async (id) => {        

        let result = await store.VehicleInspectionsService.GetVehicleInspection(id);

		if (result && result.Data) {

            let formData = {...result.Data};            

            if (formData.SubmittedToAdminOn && store.Session.Role !== Roles.Admin) {
                setViewOnly(true);
            }

            formData.COFExpiryDate = DateTime.fromISO(formData.COFExpiryDate).toFormat("yyyy-MM-dd");
            formData.DateCompleted = DateTime.fromISO(formData.DateCompleted).toFormat("yyyy-MM-dd");
            formData.NextServiceDue = DateTime.fromISO(formData.NextServiceDue).toFormat("yyyy-MM-dd");
            formData.RegistrationExpiryDate = DateTime.fromISO(formData.RegistrationExpiryDate).toFormat("yyyy-MM-dd");

            setInspection(formData);
		}
    }    

    const onSubmit = async () => {
        
        let inspectionId = params.id ? params.id : null;

        if (!params.id) {
            inspectionId = await onSave(true);
        } else {
            await onSave(true);
        }

        if (!inspectionId) return;

        let result = await store.VehicleInspectionsService.SubmitVehicleInspection(inspectionId);

        if (result?.Success) {
            setModal({
                show: true,
                header: "Success!",
                message: "Vehicle Inspection submitted successfully",
                onClose: () => {onSuccess()}
            });
        } else {
            setModal({
                show: true,
                header: "Error!",
                message: result.Message,
                onClose: () => {setModal({show: false, header: "", message: ""})}
            });
        }
    };

    const onError = (errors, e) => console.log(errors, e);

    const onSave = async (submit = false) => {

        const defaultDate = "0001-01-01";

        const data = getValues();
        
        let result = null;

        let inspectionData = {
            ...inspection,
            ...data
        };

        if (inspectionData.DateCompleted === null || inspectionData.DateCompleted === "") inspectionData.DateCompleted = defaultDate;
        if (inspectionData.RegistrationExpiryDate === null || inspectionData.RegistrationExpiryDate === "") inspectionData.RegistrationExpiryDate = defaultDate;
        if (inspectionData.NextServiceDue === null || inspectionData.NextServiceDue === "") inspectionData.NextServiceDue = defaultDate;
        if (inspectionData.COFExpiryDate === null || inspectionData.COFExpiryDate === "") inspectionData.COFExpiryDate = defaultDate;

        inspectionData.OdometerReading += "";
        inspectionData.HuboReadingCurrent += "";
        
        if (createInspection) {
            result = await store.VehicleInspectionsService.CreateVehicleInspection(inspectionData);
        } else {
            result = await store.VehicleInspectionsService.UpdateVehicleInspection(inspectionData);
        }

        if (!submit) {
            if (result?.Success) {

                setModal({
                    show: true,
                    header: "Success!",
                    message: "Vehicle Inspection saved successfully",
                    onClose: () => {onSuccess()}
                });
            } else {
                setModal({
                    show: true,
                    header: "Error!",
                    message: "An error occurred while saving the Vehicle Inspection",
                    onClose: () => {setModal({show: false, header: "", message: ""})}
                });
            }
        }

        return result?.Data;
    }

    const onSuccess = () => {
        
        setModal({show: false, header: "", message: ""});
        navigate(-1)
    }

    return <PageContainer title="Health & Safety" subtitle="Vehicle Inspection">
                {inspection ?
                    <FormContainer formTitle="Vehicle Inspection" formNumber={inspection && inspection.VehicleInspectionNumber && `VI${inspection.VehicleInspectionNumber}`} viewOnly={viewOnly}>
                        <FormProvider {...form}>
                            <Form className="text-black" onSubmit={handleSubmit(onSubmit, onError)}>
                                <DriverAndVehicleInformation disabled={viewOnly} />
                                <VehiclePartsGroup
                                    label="Vehicle Interior and Operation (Please make a selection for each item)"
                                    startIndex={0}
                                    endIndex={8}
                                    disabled={viewOnly}
                                />
                                <VehiclePartsGroup
                                    label="Vehicle Exterior and Under-body (please make a selection for each item)"
                                    startIndex={8}
                                    endIndex={17}
                                    disabled={viewOnly}
                                /> 
                                <VehiclePartsGroup
                                    label="Rear Lights (Please make a selection for each item)"
                                    startIndex={17}
                                    endIndex={21}
                                    disabled={viewOnly}
                                />  
                                <VehiclePartsGroup
                                    label="Front Lights (Please make a selection for each item)"
                                    startIndex={21}
                                    endIndex={24}
                                    disabled={viewOnly}
                                />                    
                                <VehiclePartsGroup
                                    label="Heavy Vehicles Only (Please make a selection for each item)"
                                    startIndex={24}
                                    endIndex={27}
                                    disabled={viewOnly}
                                    additionalComments
                                />
                                <CompletedBySection submittedToAdminOn={inspection?.SubmittedToAdminOn ? inspection.SubmittedToAdminOn : ""} disabled={viewOnly} onSave={onSave} submitEnabled={submitEnabled}/>
                            </Form>
                        </FormProvider>
                    </FormContainer> :
                    <div className="text-center pt-5 mt-5">
                        <Spinner variant="light"></Spinner>
                    </div>
                }
                <MessageModal modal={modal}/>
            </PageContainer>
}