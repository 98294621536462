import { useState } from "react";
import { Col, Row, Button, Stack } from "react-bootstrap";
import FormSection from './FormSection';
import InputField from "./InputField";
import CheckboxField from "./CheckboxField";
import { useNavigate } from "react-router-dom";

export default function CompletedBySection({ submittedToAdminOn, onSave, disabled, submitEnabled }) {
    const [formComplete, setFormComplete] = useState(false);
	const navigate = useNavigate();

    return <FormSection> 
                <Stack gap={3}>          
                    <Row>
                        <Col className="col-12 col-xxl-6 mb-3 mb-xxl-0">
                            <InputField label="Completed By" name="CompletedBy" disabled ></InputField>
                        </Col>
                        <Col className="col-12 col-xxl-6">
                            <InputField label="Date Submitted" name="SubmittedToAdminOn" disabled ></InputField>
                        </Col>
                    </Row>
                    <Row className="d-print-none">
                        <Col>
                            <CheckboxField
                                label={"By checking this box you confirm the form is complete and all information is true."}
                                name={"FormComplete"}
                                onChange={(event) => setFormComplete(event.target.checked)}
                                {...((submittedToAdminOn || disabled) && ({ disabled: true }))}
                                required
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-8 col-xxl-4 d-print-none">
                            <Stack direction={"horizontal"} gap={3}>
                                    <Button className="w-100" variant="secondary" onClick={() => {onSave()}} {...(disabled && ({ disabled }))}>Save</Button>
                                    <Button
                                        type="submit"
                                        className="w-100"
                                        variant="secondary"
                                        disabled={submittedToAdminOn || !formComplete || !submitEnabled}
                                    >
                                        {submittedToAdminOn ? "Submitted" : "Submit"}
                                    </Button>
                                </Stack>
                        </Col>
                        <Col className="col-4 col-xxl-2 offset-xxl-6 d-print-none">
                            <Button className="w-100" variant="secondary" onClick={() => navigate(-1)}>Cancel</Button>
                        </Col>
                    </Row>
                </Stack>
            </FormSection>
}