import * as yup from "yup";

const VehicleInspectionValidationSchema = yup.object().shape({
    EmployeeName: yup.string().required("Driver name is required"),
    RegistrationNumber: yup.string().required("Registration number is required"),
    OdometerReading: yup.string().required("Speedometer reading is required"),
    WOFCOFExpiryDate: yup
                        .date("Must be a valid date")
                        .nullable(true)
                        .transform(value => (value instanceof Date && !isNaN(value) ? value : null))
                        .typeError("Must be a valid date"),
    HuboReadingEntry: yup
                        .date("Must be a valid date")
                        .nullable(true)
                        .transform(value => (value instanceof Date && !isNaN(value) ? value : null))
                        .typeError("Must be a valid date"),
    DateCompleted: yup.date("Must be a valid date").typeError("Must be a valid date").required("Date completed is required"),
    RegistrationExpiryDate: yup.date("Must be a valid date").typeError("Must be a valid date").required("Registration expiry date is required"),
    NextServiceDue: yup
                        .date("Must be a valid date")
                        .nullable(true)
                        .transform(value => (value instanceof Date && !isNaN(value) ? value : null))
                        .typeError("Must be a valid date"),
    AdditonalComments: yup.string(),
    Items:  yup.array()
            .of(
                yup.object().shape({
                    Result: yup.number().required("You must select an option for each item").test("is-valid", "You must select an option for each item", value => value !== 3),                   
                })
    ),
    FormComplete: yup.boolean().oneOf([true], "This box must be checked to submit the form")
});

export default VehicleInspectionValidationSchema;