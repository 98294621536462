import { Col, Row, Stack } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import InputField from "../../../../Core/Forms/InputField";
import FormSection from "../../../../Core/Forms/FormSection";
import PlusButton from "../../../../Core/Forms/PlusButton";
import MinusButton from "../../../../Core/Forms/MinusButton";
import PaddedCol from "../../../../Core/PaddedCol";

const HoursWorked = ({disabled}) => {

    const { control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control: control,
        name: "Hours",
    });

    return (
        <FormSection title="Hours Worked">            
            <Stack gap={3}>
                {fields.map((hw, index) =>
                    <Row className="ps-2 me-0" key={`HoursWorked-${index}`}>
                        <PaddedCol className="col-12 col-xxl-5 mb-3 mb-xxl-0">
                            <InputField labelClass={index > 0 ? "d-block d-xxl-none" : ""} label="Employee Name" name={`Hours.${index}.EmployeeName`} disabled={disabled}/>
                        </PaddedCol>
                        <PaddedCol className="col-4 col-md-3 col-xxl-1 mb-3 mb-xxl-0">                                    
                            <InputField labelClass={index > 0 ? "d-block d-xxl-none" : ""} label="Mon" name={`Hours.${index}.Mon`} disabled={disabled}/>
                        </PaddedCol>
                        <PaddedCol className="col-4 col-md-3 col-xxl-1 mb-3 mb-xxl-0">
                            <InputField labelClass={index > 0 ? "d-block d-xxl-none" : ""} label="Tue" name={`Hours.${index}.Tue`} disabled={disabled}/>
                        </PaddedCol>
                        <PaddedCol className="col-4 col-md-3 col-xxl-1 mb-3 mb-xxl-0">
                            <InputField labelClass={index > 0 ? "d-block d-xxl-none" : ""} label="Wed" name={`Hours.${index}.Wed`} disabled={disabled}/>
                        </PaddedCol>
                        <PaddedCol className="col-4 col-md-3 col-xxl-1 mb-3 mb-xxl-0">
                            <InputField labelClass={index > 0 ? "d-block d-xxl-none" : ""} label="Thu" name={`Hours.${index}.Thu`} disabled={disabled}/>
                        </PaddedCol>
                        <PaddedCol className="col-4 col-md-3 col-xxl-1">
                            <InputField labelClass={index > 0 ? "d-block d-xxl-none" : ""} label="Fri" name={`Hours.${index}.Fri`} disabled={disabled}/>
                        </PaddedCol>
                        <PaddedCol className="col-4 col-md-3 col-xxl-1">
                            <InputField labelClass={index > 0 ? "d-block d-xxl-none" : ""} label="Sat" name={`Hours.${index}.Sat`} disabled={disabled}/>
                        </PaddedCol>
                        <PaddedCol className="col-4 col-md-3 col-xxl-1">
                            <InputField labelClass={index > 0 ? "d-block d-xxl-none" : ""} label="Sun" name={`Hours.${index}.Sun`} disabled={disabled}/>
                        </PaddedCol>
                    </Row>
                )}
                {!disabled &&
                    <Row>
                        <Col className="col-6 d-flex justify-content-start">
                            <Stack direction="horizontal">
                                <PlusButton                                        
                                    onClick={
                                        () => {
                                            append({
                                                RemediationReportId: null,
                                                Id: null,
                                                EmployeeName: "",
                                                Mon: "",
                                                Tue: "",
                                                Wed: "",
                                                Thu: "",
                                                Fri: "",
                                                Sat: "",
                                                Sun: ""
                                            })
                                        }
                                    }
                                    disabled={disabled}
                                />
                                {
                                    fields.length > 1 &&
                                    <MinusButton onClick={() => {remove(-1)}} disabled={disabled}/>
                                }
                            </Stack>
                        </Col>
                    </Row>
                }
            </Stack>
        </FormSection>
    )
}

export default HoursWorked;