import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav, NavbarBrand, Button, Offcanvas, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useSessionStore } from "../../Stores/SessionStore";
import { faBars, faCircle, faGear, faHardHat, faPenRuler, faTruck } from "@fortawesome/pro-regular-svg-icons";
import { Roles } from "../../Utilities/Enums";

function SidebarNavItem({ text, to }) {
	
	function getClass({ isActive }) {
		return [
			isActive ? "bg-light" : "bg-white",
			"text-dark",
			"d-flex", 
			"align-items-center", 
			"text-decoration-none",
			"w-100", 
			"px-4", 
			"py-2"
		].join(" ");
	}

	return <Nav.Item className="">
				<NavLink to={ to } className={ getClass }>
					<FontAwesomeIcon fontSize={8} fixedWidth color="#555" icon={ faCircle } /> <span className="ms-2">{ text }</span>
				</NavLink>
			</Nav.Item>
}

function SidebarHeaderItem({ text, icon }) {
	return 	<Nav.Item className="w-100 p-2 user-select-none">
				<FontAwesomeIcon fixedWidth color="#555" icon={ icon } /><span className="ms-2">{ text }</span>
			</Nav.Item>
}

export default function Sidebar() {
	const store = useSessionStore();

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const offCanvasStyle= { width: "20em"};

	return 	<Container className="h-100 p-0">
				<Button className="d-xl-none bg-primary mt-2 border-0 ps-1" onClick={handleShow}>
					<FontAwesomeIcon fixedWidth color="#fff" size="xl" icon={ faBars } />
				</Button>
				<Offcanvas show={show} onHide={handleClose} backdrop={false} style={offCanvasStyle} responsive="xl" className="h-100">
					<Container className="h-100 bg-white">
						<Offcanvas.Header closeButton>
							<NavbarBrand>
								<h4>Concrete Specialists</h4>
							</NavbarBrand>
						</Offcanvas.Header>
						<Offcanvas.Body className="pt-0">
						<Nav>
							<NavbarBrand className="d-none d-xl-block p-2 d-flex justify-content-center align-items-center">
								<h4 className="">Concrete Specialists</h4>
							</NavbarBrand>
							<SidebarHeaderItem icon={ faPenRuler } text="Projects"></SidebarHeaderItem>
							{
								store.Session.Role !== Roles.TeamLeader &&
								<SidebarNavItem to="/SalesQuotes" text="Estimates"></SidebarNavItem>
							}
							{
								store.Session.Role !== Roles.SalesRep &&
								<SidebarNavItem to="/JobSheets" text="Job Sheets"></SidebarNavItem>
							}
							<SidebarHeaderItem icon={ faHardHat } text="Health and Safety"></SidebarHeaderItem>
							<SidebarNavItem to="/AccidentsIncidents" text="Accidents & Incidents"></SidebarNavItem>
							<SidebarNavItem to="/VehicleInspections" text="Vehicle Inspections"></SidebarNavItem>
							<SidebarHeaderItem icon={ faTruck } text="Suppliers"></SidebarHeaderItem>
							{
								store.Session.Role === Roles.Admin &&
								<>
									<SidebarHeaderItem icon={ faGear } text="Admin Settings"></SidebarHeaderItem>
									<SidebarNavItem to="/Users" text="Users"></SidebarNavItem>
								</>
							}
						</Nav>
						</Offcanvas.Body>
					</Container>
				</Offcanvas>
			</Container>
}