const VarianceReport = {
    AdditionalInformation: "",
	MaterialsPurchaseOnly: false,
	FixedPrice: false,
	CallOutRequest: false,
	CustomerSignatureBase64: "",
	CustomerSignDate: "0001-01-01",
	Date: null,
	DescriptionOfWorks: "",
	JobSheetId: "",
	Variation: false,
	Hours: [{
		RemediationReportId: null,
		Id: null,
		EmployeeName: "",
		Mon: "",
		Tue: "",
		Wed: "",
		Thu: "",
		Fri: "",
		Sat: "",
		Sun: ""
	}],
	Materials: [{
		RemediationReportId: null,
		Id: null,
		CostPrice: "",
		Description: "",
		OrderNumber: "",
		Quantity: "",
		Supplier: ""
	}]
}

export default VarianceReport;