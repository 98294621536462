import { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { Roles } from "../../../../../Utilities/Enums";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import PrestartFormValidationSchema from "../Validation/PrestartFormValidationSchema";
import Prestart from "./Prestart";
import MessageModal from "../../../../Core/Forms/MessageModal";
import PageContainer from "../../../../Core/PageContainer";
import FormContainer from "../../../../Core/Forms/FormContainer";
import FormSection from "../../../../Core/Forms/FormSection";
import AssignToTeamLeader from "../../../Common/Components/AssignToTeamLeader";
import SiteDetails from "./SiteDetails";
import Team from "./Team";
import Hazards from "./Hazards";
import JobDescriptions from "../../SalesQuotes/Details/JobDescriptions";
import CompletedBySection from "../../../../Core/Forms/CompletedBySection";

export default function PreStartForm() {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
	const store = useSessionStore();
    const createPrestartForm = location?.pathname === "/JobSheets/Prestart/Add" ? true : false;
    const adminDisabled = store.Session.Role === Roles.Admin ? false : true;
    
    const form = useForm({mode: "onChange", resolver: yupResolver(PrestartFormValidationSchema)});

    const { handleSubmit, formState } = form;

    const [prestartForm, setPrestartForm] = useState(null);
    const [viewOnly, setViewOnly] = useState(location.state?.action && location.state.action === "view" ? true : false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [modal, setModal] = useState({show: false, header: "", message: ""});    

    useEffect(() => {

        if (params.id) {
            
            getPrestartForm(params.id);
            
        } else if (location.state?.jobSheetId) {

            (async () => {
                let jobSheet = await getJobSheet(location.state.jobSheetId);
                                
                setPrestartForm({
                    ...Prestart,
                    ...jobSheet,
                    JobSheetNumber: `JS-EST${jobSheet.SalesQuoteNumber}`,
                    JobSheetId: location.state.jobSheetId
                });                
            })();
        }        
	}, []);

    useEffect(() => {
        if (prestartForm) form.reset({...prestartForm, Hazards: []}, {keepDirtyValues: true});
    }, [prestartForm]);

    const getPrestartForm = async (prestartFormId) => {
            
        let result = await store.PrestartFormsService.GetPrestartForm(prestartFormId);

        if (result && result.Data) {
            if (result.Data.PrestartForm.SubmittedToAdminOn && store.Session.Role !== Roles.Admin) {
                setViewOnly(true);
            }

            setPrestartForm({
                ...result.Data.PrestartForm,
                CompletedBy: result.Data.PrestartForm.CreatedBy,
                JobDescriptions: result.Data.JobDescriptions,
                JobSheetNumber: params.jobNumber ? params.jobNumber : "",
                Site: result.Data.Site,
                Date: result.Data.PrestartForm.Date.substring(0, 4) !== "0001" ? DateTime.fromISO(result.Data.PrestartForm.Date).toFormat("yyyy-MM-dd") : null
            })
        }
    }

    useEffect(() => {

        if (Object.keys(formState.errors).length === 0) {
            setSubmitEnabled(true);
        } else {
            setSubmitEnabled(false);
        }
    }, [formState]);

    const getJobSheet = async (jobSheetId) => {

        let result = await store.JobSheetsService.GetJobSheet(jobSheetId);

        if (result && result.Data) { 
            
            return {
                SalesQuoteId: result.Data.SalesQuote.Id,
                SalesQuoteNumber: result.Data.SalesQuote.SalesQuoteNumber,
                TeamLeaderId: result.Data.SalesQuote.TeamLeaderId,
                Site: result.Data.SalesQuote.Site,
                JobDescriptions: result.Data.SalesQuote.JobDescriptions
            }
        }

        return {};
    }

    const onSubmit = async () => {

        let prestartFormId = params.id ? params.id : null;

        if (!params.id) {
            prestartFormId = await onSave(true);
        } else {
            await onSave(true);
        }

        if (!prestartFormId) return;

        let result = await store.PrestartFormsService.SubmitPrestartForm(prestartFormId);

        if (result?.Success) {
            setModal({
                show: true,
                header: "Success!",
                message: "Prestart Form submitted successfully",
                onClose: () => {onSuccess()}
            });
        } else {
            setModal({
                show: true,
                header: "Error!",
                message: result.Message,
                onClose: () => {setModal({show: false, header: "", message: ""})}
            });
        }
    }

    const onError = (errors, e) => console.log(errors, e);

    const onSave = async (submit = false) => {

        let data = form.getValues();

        let result = null;

        let newHazards = data.Hazards.filter((hazard, index) => hazard.HazardControls !== null);

        data.Hazards = newHazards;

        if (data.Date === null) data.Date = "0001-01-01";

        delete data.Site;
        delete data.JobDescriptions;
        delete data.OtherHazards;
        delete data.JobSheetNumber;
        delete data.SalesQuoteNumber;
        delete data.PreStartFormNumber;
        delete data.OtherHazards;
        delete data.CheckedHazards;

        if (createPrestartForm) {

            result = await store.PrestartFormsService.CreatePrestartForm(data);

        } else {            

            result = await store.PrestartFormsService.UpdatePrestartForm(data);
        }

        if (!submit) {
            if (result?.Success) {

                setModal({
                    show: true,
                    header: "Success!",
                    message: "Prestart Form saved successfully",
                    onClose: () => {onSuccess()}
                });
            } else {
                setModal({
                    show: true,
                    header: "Error!",
                    message: "An error occurred while saving the Prestart Form",
                    onClose: () => {setModal({show: false, header: "", message: ""})}
                });
            }
        }

        return result?.Data;
    }

    const onSuccess = () => {
        setModal({show: false, header: "", message: ""});
        navigate(-1);
    }

	return 	<PageContainer title="Projects" subtitle="Job Sheets - Prestart Form">
				{prestartForm ?
                    <FormContainer
                        formTitle="Prestart Form"
                        formNumber={prestartForm && prestartForm.PreStartFormNumber && prestartForm.JobSheetNumber && `${prestartForm.JobSheetNumber}-PR${prestartForm.PreStartFormNumber}`}
                        viewOnly={viewOnly}
                    >
                        <FormProvider { ...form }>
                            <Form className="text-black" onSubmit={handleSubmit(onSubmit, onError)}>
                                <FormSection title="">
                                    <AssignToTeamLeader value={prestartForm.TeamLeaderId} date={prestartForm && prestartForm.Date} disabled={viewOnly || adminDisabled}/>
                                </FormSection>
                                <SiteDetails details={null}/>
                                <JobDescriptions
                                    jobDescriptions={prestartForm && prestartForm.JobDescriptions}
                                    add={() => {}}
                                    remove={() => {}}
                                    update={() => {}}
                                    jobTotal={""}
                                    disabled={true}
                                />
                                <Team disabled={viewOnly}/>
                                <Hazards hazards={prestartForm && prestartForm.Hazards} disabled={viewOnly}/>
                                <CompletedBySection  
                                    SubmittedToAdminOn={prestartForm?.SubmittedToAdminOn ? prestartForm?.SubmittedToAdminOn : ""}
                                    disabled={viewOnly}
                                    onSave={onSave}
                                    submitEnabled={submitEnabled}
                                />
                            </Form>
                        </FormProvider>
                    </FormContainer> :
                    <div className="text-center pt-5 mt-5">
                        <Spinner variant="light"></Spinner>
                    </div>
                }
                <MessageModal modal={modal}/>
			</PageContainer>
}