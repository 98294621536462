import { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";

export default function SignIn() {
	const store = useSessionStore();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [signingIn, setSigningIn] = useState(false);
	const [error, setError] = useState(false);

	async function signIn() {
		if(email && password) {
			setSigningIn(true);
			setError(false);

			const result = await store.UsersService.Login(email, password);

			setSigningIn(false);

			if(result && result.Success) {
				store.SetSession(result.Data);
			} else {
				setError(true);
			}
		}
	}

	return 	<Container>
				<Row className="d-flex justify-content-center mt-5 pt-5">
					<Col lg={6}>
						<Card>
							<Card.Header>
								<h5>Sign In</h5>
							</Card.Header>
							<Card.Body>
								<Form>
									<Form.Label>Email Address</Form.Label>
									<Form.Control className="mb-3" type="email" value={ email } onChange={ e => setEmail(e.target.value)}></Form.Control>

									<Form.Label>Password</Form.Label>
									<Form.Control type="password" value={ password } onChange={ e => setPassword(e.target.value)}></Form.Control>
								</Form>
								{
									error &&
									<span className="d-block text-danger mt-2">Invalid Email Address or Password</span>
								}
							</Card.Body>
							<Card.Footer className="d-flex justify-content-end">
								<Button onClick={ signIn } disabled={ signingIn }>{ signingIn ? "Signing In..." : "Sign In" }</Button>
							</Card.Footer>
						</Card>
					</Col>
				</Row>
			</Container>
}