import APIBase from "./APIBase";

/**
 * @typedef SalesQuoteVM
 * @property {string} Id
 * @property {string} SQNumber
 * @property {string} Date
 * @property {string} SalesRep
 * @property {string} Referral
 * @property {string} ClientName
 * @property {string} JobSheetNumber
 * @property {string} TeamLeader
 * @property {string} Status
 * @property {number} Total
 */

/**
 * @typedef SalesQuotesService
 * @type {SalesQuotesService}
 */
export default class SalesQuotesService extends APIBase {
	/**
	 * @param {string} apiUrl
	 * @param { import("../Stores/SessionStore").SessionStore } sessionStore
	 **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "SalesQuotes", sessionStore);
	}

	/**
	 * @returns { Promise<import("./APIBase").EnumerableRequestResult<SalesQuoteVM>> }
	 */
	async GetSalesQuotes(search, status) {
		return this.Get(`GetSalesQuotes?search=${encodeURIComponent(search)}&status=${status}`);
	}
	
	async GetSalesQuote(id) {
		return this.Get(`GetSalesQuote?salesQuoteId=${encodeURIComponent(id)}`);
	}

	async CreateSalesQuote(quote) {
		return this.Post(`CreateSalesQuote`, quote);
	}

	async UpdateSalesQuote(quote) {
		return this.Post(`UpdateSalesQuote`, quote);
	}

	async SubmitSalesQuote(salesQuoteId) {
		return this.Post(`SubmitSalesQuote?salesQuoteId=${encodeURIComponent(salesQuoteId)}`);
	}

	async SubmitSalesQuoteToTeamLeader(salesQuoteId, teamLeaderId) {
		return this.Post(`SubmitSalesQuoteToTeamLeader?salesQuoteId=${encodeURIComponent(salesQuoteId)}&teamLeaderId=${encodeURIComponent(teamLeaderId)}`);
	}

	async GetSalesQuotesByTeamLeader(teamLeaderId) {
		return this.Get(`GetSalesQuotesByTeamLeader?teamLeaderId=${encodeURIComponent(teamLeaderId)}`);
	}
}