import APIBase from "./APIBase";

/**
 * @typedef UsersService
 * @type {UsersService}
 */
export default class UsersService extends APIBase {
	/**
	 * @param {string} apiUrl
	 * @param { import("../Stores/SessionStore").SessionStore } sessionStore
	 **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "Users", sessionStore);
	}

	async Login(email, password) {
		return this.Post(`Login?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`, null);
	}

	async CreateUser(user) {
		return this.Post(`CreateUser`, user);
	}

	async UpdateUser(user) {
		return this.Post(`UpdateUser`, user);
	}

	async DeleteUser(userId) {
		return this.Post(`DeleteUser?userId=${userId}`, null);
	}

	async GetUsers(search) {
		return this.Get(`GetUsers?search=${encodeURIComponent(search)}`);
	}

	async GetUser(userId) {
		return this.Get(`GetUser?userId=${userId}`);
	}
}