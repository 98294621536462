import { Col, Row, Stack } from 'react-bootstrap';
import FormSection from "../../../../Core/Forms/FormSection";
import InputField from "../../../../Core/Forms/InputField";
import CheckboxField from "../../../../Core/Forms/CheckboxField";

const TypeOfWorks = ({ disabled }) => {
    
    return (
        <FormSection title="Type of Works">
            <Stack gap={3}>
                <Row>
                    <Col className="col-6 col-md-3 mb-3 mb-md-0">
                        <CheckboxField label="Fixed Price" name="FixedPrice" disabled={disabled}/>                                            
                    </Col>
                    <Col className="col-6 col-md-3">
                        <CheckboxField label="Variation" name="Variation" disabled={disabled}/>
                    </Col>
                    <Col  className="col-6 col-md-3 mb-3 mb-md-0">                                            
                        <CheckboxField label="Call-out Request" name="CallOutRequest" disabled={disabled}/>
                        
                    </Col>
                    <Col className="col-6 col-md-3">
                        <CheckboxField label="Materials Purchase Only" name="MaterialsPurchaseOnly" disabled={disabled}/>
                    </Col>
                </Row>
                <InputField label="Description of Works" name="DescriptionOfWorks" defaultValue="" type="textarea" disabled={disabled}/>
                <InputField label="Additional Information" name="AdditionalInformation" defaultValue="" type="textarea" disabled={disabled}/>
            </Stack>
        </FormSection>
    )
}

export default TypeOfWorks;