import { useState, useEffect } from 'react';
import { useSessionStore } from "../../../../Stores/SessionStore";
import SelectField from '../../../Core/Forms/SelectField';

export default function UserRoleSelect({label, name, value, required, disabled, role}) {
    const store = useSessionStore();

    const [roleUsers, setRoleUsers] = useState([{ value: "", label: "Please Select" }]);

    useEffect(() => {
        getRoleUsers();
    }, []);

    const getRoleUsers = async () => {
        let result = await store.UsersService.GetUsers("");

        if (result?.Success) {
            let roleUsers = result.Data.filter(user => user.Role === role);
            let options = roleUsers.map(tl => {
                return { value: tl.Id, label: tl.FirstName + " " + tl.LastName }
            });
            
            options.unshift({ value: "", label: "Please Select" });
            setRoleUsers(options);
        }
    }

    return <SelectField label={label} name={name} {...{options: roleUsers, value}} required={required} disabled={disabled}/>
}