import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

export default function Headerbar({ onSearchClicked }) {

    const [search, setSearch] = useState("");

    return <Row>
        <Col className="col-12 col-lg-3 mb-3 mb-lg-0">
            <Form.Control placeholder="Search" value={search} onChange={e => setSearch(e.target.value)}></Form.Control>
        </Col>
        <Col className="col-6 col-md-3 col-lg-2">
            <Button variant="primary" className="w-100" onClick={e => onSearchClicked(search)}>Search</Button>
        </Col>
        <Col className="col-6 col-md-3 offset-md-3 col-lg-2 offset-lg-5">
            <a href="#/User" className="btn btn-secondary w-100">Add New User</a>
        </Col>
    </Row>
}