import APIBase from "./APIBase";

/**
 * @typedef JobSheetVM
 * @property {string} Id
 * @property {string} JobSheetNumber
 * @property {string} SQNumber
 * @property {string} Date
 * @property {string} SalesRep
 * @property {string} Referral
 * @property {string} ClientName
 * @property {string} Status
 */

/**
 * @typedef JobSheetsService
 * @type {JobSheetsService}
 */
export default class JobSheetsService extends APIBase {
	/**
	 * @param {string} apiUrl
	 * @param { import("../Stores/SessionStore").SessionStore } sessionStore
	 **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "JobSheets", sessionStore);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<JobSheetVM> }
	 */
	async GetJobSheets(search, status) {
		return this.Get(`GetJobSheets?search=${encodeURIComponent(search)}&status=${status}`);
	}

	async GetJobSheet(id) {
		return this.Get(`GetJobSheet?jobSheetId=${encodeURIComponent(id)}`);
	}

	async UpdateJobSheet(jobSheet) {
		return this.Post(`UpdateJobSheet`, jobSheet);
	}

	async SubmitJobSheet(jobSheetId) {
		return this.Post(`SubmitJobSheet?jobSheetId=${encodeURIComponent(jobSheetId)}`);
	}

	async CreateRemediationReport(varianceReport) {
		return this.Post(`CreateRemediationReport`, varianceReport);
	}

	async UpdateRemediationReport(varianceReport) {
		return this.Post(`UpdateRemediationReport`, varianceReport);
	}

	async GetJobSheetsByTeamLeader(teamLeaderId) {
		return this.Get(`GetJobSheetsByTeamLeader?teamLeaderId=${encodeURIComponent(teamLeaderId)}`);
	}
}