import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner, Stack } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSessionStore } from "../../../../Stores/SessionStore";
import getUserSchema from "../Validation/UserValidationSchema";
import InputField from "../../../Core/Forms/InputField";
import PasswordField from "../../../Core/Forms/PasswordField";
import PageContainer from "../../../Core/PageContainer";
import Panel from "../../../Core/Panel";
import MessageModal from "../../../Core/Forms/MessageModal";

export default function UserDetails() {
	const store = useSessionStore();
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	
	const viewOnly = location.state?.action && location.state.action === "view" ? true : false;
	const form = useForm({mode: "onChange", resolver: yupResolver(getUserSchema(!params.id))});

	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState({show: false, header: "", message: ""});

	async function handleSaveClicked(data) {
		let result = null;	

		setSaving(true);

		if(params.id) {
			result = await store.UsersService.UpdateUser(data);
		} else {
			result = await store.UsersService.CreateUser(data);
		}

		setSaving(false);

		if(result && result.Success) {
			setModal({
				show: true,
				header: "Success!",
				message: "User saved successfully",
				onClose: () => {navigate(-1)}
			});
		} else {
			setModal({
				show: true,
				header: "Error!",
				message: result.Message,
				onClose: () => {setModal({show: false, header: "", message: ""})}
			});
		}
	}

	async function getUser() {
		setLoading(true);
		const result = await store.UsersService.GetUser(params.id);

		if(result && result.Success) {
			form.reset({
				Id: params.id,
				ConfirmPassword: "",
				Password: "",
				Email: result.Data.Email,
				FirstName: result.Data.FirstName,
				LastName: result.Data.LastName,
				Role: result.Data.Role,
				ContactNumber: result.Data.ContactNumber
			});

			setLoading(false);
		}	
	}

	useEffect(() => {
		if (params && params.id) {
			getUser();
		}
	}, [params, params.id]);

	return 	<PageContainer title="Admin" subtitle="Users">
				<Panel title={ `${params.id ? "Edit" : "Add" } User` }>
					{
						loading &&
						<div className="text-center">
							<Spinner></Spinner>
						</div>
					}
					{
						!loading &&
						<FormProvider { ...form }>
							<Form onSubmit={ form.handleSubmit(handleSaveClicked) }>
								<Row className="mb-3">
									<Col className="col-12 col-md-6">
										<InputField label="Name" name="FirstName" required={true} disabled={viewOnly}></InputField>
									</Col>
									<Col className="col-12 col-md-6">
										<InputField label="Surname" name="LastName" required disabled={viewOnly}></InputField>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col className="col-12 col-md-6">
										<InputField type="email" label="Email Address" name="Email" required disabled={viewOnly}></InputField>
									</Col>
									<Col className="col-12 col-md-6">
										<InputField label="Contact Number" name="ContactNumber" required disabled={viewOnly}></InputField>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col className="col-12 col-md-6">
										<PasswordField label="Password" name="Password" required={ !params.id } disabled={viewOnly}/>
									</Col>
									<Col className="col-12 col-md-6">
										<PasswordField label="Confirm Password" name="ConfirmPassword" required={ !params.id } disabled={viewOnly}/>
									</Col>
								</Row>
								<Row className="mb-4">
									<Col className="col-12 col-md-6">
										<Form.Label>Role</Form.Label>
										<Form.Select required {...form.register("Role")} disabled={viewOnly}>
											<option value="">Select Role</option>
											<option value="1">Sales Rep</option>
											<option value="2">Team Leader</option>
											<option value="3">Admin</option>
										</Form.Select>
									</Col>
								</Row>							
								<hr style={{ marginLeft: "-1rem", marginRight: "-1rem" }}/>
								<Row>
									<Col className="d-flex justify-content-end">
										<Stack gap={3} direction="horizontal">
											<Button type="submit" disabled={saving || viewOnly}>{ saving ? "Saving..." : "Save" }</Button>
											<Button disabled={saving} onClick={() => {navigate(-1)}}>Cancel</Button>
										</Stack>
									</Col>
								</Row>
							</Form>
						</FormProvider>
					}
				</Panel>
				<MessageModal modal={modal}/>
			</PageContainer>
}