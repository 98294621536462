import APIBase from "./APIBase";

/**
 * @typedef RemediationReportsService
 * @type {RemediationReportsService}
 */

/**
 * @typedef RemediationReportsService
 * @type {RemediationReportsService}
 */
export default class RemediationReportsService extends APIBase {
	/**
	 * @param {string} apiUrl
	 * @param { import("../Stores/SessionStore").SessionStore } sessionStore
	 **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "RemediationReports", sessionStore);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<RemediationReportVM> }
	 */	

	async GetRemediationReports(jobSheetId) {
		return this.Get(`GetRemediationReports?jobSheetsId=${encodeURIComponent(jobSheetId)}`);
	}

	async GetRemediationReport(reportId) {
		return this.Get(`GetRemediationReport?reportId=${encodeURIComponent(reportId)}`);
	}
    
    async CreateRemediationReport(report) {
		return this.Post(`CreateRemediationReport`, report);
	}

	async UpdateRemediationReport(report) {
		return this.Post(`UpdateRemediationReport`, report);
	}

	async SubmitRemediationReport(remediationReportId) {
		return this.Post(`SubmitRemediationReport?remediationReportId=${encodeURIComponent(remediationReportId)}`);
	}
}