import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { ErrorMessage } from "@hookform/error-message";

export default function PasswordField({ name, label, onChange, className, disabled, required }) {
	const { register, watch, formState: { errors } } = useFormContext();
	let watchField = watch(name);

    const [showPassword, setShowPassword] = useState(false);

	return 	<Form.Group {...(className && ({ className }))}>
				
				{label && 	
					<Form.Label>{ label }</Form.Label>
				}
                    <InputGroup>
                        <Form.Control
                            type={showPassword ? "type" : "password"}
                            disabled={disabled}
                            className={(required && watchField === "" ? "border-danger" : "")}
                            { ...register(name, { required: required, onChange: onChange && onChange}) }
                        />					
                        <InputGroup.Text>
                            <FontAwesomeIcon
                                icon={ showPassword ? faEyeSlash : faEye}
                                fixedWidth
                                onClick={() => {setShowPassword(!showPassword)}}
                            />
                        </InputGroup.Text>
                    </InputGroup>
                    <ErrorMessage
                        errors={errors}
                        name={name}
                        render={({ message }) => <Form.Text className="text-danger">{ message }</Form.Text>}
                    />
			</Form.Group>
}