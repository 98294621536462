import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useSessionStore } from "../../../../Stores/SessionStore";
import { Roles } from "../../../../Utilities/Enums";

export default function TeamLeaderFilter({status, onTeamLeaderSelected}) {
    const store = useSessionStore();

    const [teamLeaders, setTeamLeaders] = useState([]);

    useEffect(() => {
		getTeamLeaders();
	}, []);

	const getTeamLeaders = async () => {
        let result = await store.UsersService.GetUsers("");

        if (result?.Success) {
            let roleUsers = result.Data.filter(user => user.Role === Roles.TeamLeader);
            let options = roleUsers.map(tl => {
                return { value: tl.Id, label: tl.FirstName + " " + tl.LastName }
            });
            
            options.unshift({ value: "", label: "Select Team Leader..." });
            setTeamLeaders(options);
        }
    }

    return <Form.Select
                placeholder="Select Team Leader..."
                onChange={ e => {

                    onTeamLeaderSelected(e.target.selectedIndex === 0 ? null : e.target.value);
                }}
            >
                {
                    teamLeaders.map(tl => <option key={tl.value} value={tl.value}>{tl.label}</option>)
                }
            </Form.Select>
}