import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

export default function InputField({ name, label, labelClass, type, value, defaultValue, placeholder, onChange, className, rows, disabled, required, unreg }) {
	const { register, watch, formState: { errors }, unregister } = useFormContext();
	let watchField = watch(name);

	useEffect(() => {
		return () => {
			unregister(name);
		}
	}, []);

	useEffect(() => {
		
		if (unreg) {

			unregister(name);
		} else {

			register(name, { required: required, onChange: onChange && onChange});
			watchField = watch(name);
		}
	}, [unreg]);

	return 	<Form.Group {...(className && ({ className }))}>
				
				{label && 	
					<Form.Label className={labelClass ? labelClass : ""}>{ label }</Form.Label>
				}
					<Form.Control
						type={type}
						disabled={disabled}
						className={(`${required && watchField === "" ? "border-danger" : ""} ${className ? className : ""}`)}
						{ ...register(name, { required: required, onChange: onChange && onChange}) }
						{...(defaultValue && ({ defaultValue }))}
						{...(value && ({ value }))}
						{...(placeholder && ({ placeholder }))}
						{...(type === "textarea" && ({rows: rows ? rows : 3}))}
						{...(type === "textarea" && ({as: "textarea"}))}
					/>					
					<ErrorMessage
						errors={errors}
						name={name}
						render={({ message }) => <Form.Text className="text-danger">{ message }</Form.Text>}
					/>
			</Form.Group>
}