import { Col, Row, Stack } from 'react-bootstrap';
import InputField from '../../../../Core/Forms/InputField';
import FormSection from '../../../../Core/Forms/FormSection';

const SiteDetails = () => {

    return (
        <FormSection title="Details">
            <Stack gap={3}>
                <Row >
                    <p className="fw-bold fs-6">Site Address</p>
                </Row>
                <Row >
                    <Col className="col-12 col-xxl-6 mb-3 mb-xxl-0">
                        <InputField label="Address Line 1" name="Site.AddressLine1" disabled />
                    </Col>
                    <Col className="col-12 col-xxl-6">
                        <InputField label="Address Line 2" name="Site.AddressLine2" disabled />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 col-xxl-6">
                        <InputField label="Job Sheet No." name="JobSheetNumber" value="" disabled />
                    </Col>                    
                </Row>
            </Stack>
        </FormSection>
    )
}

export default SiteDetails;