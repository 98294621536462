import { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { InjuryTypes, Roles } from "../../../../../Utilities/Enums";
import AccidentAndIncidentValidationSchema from "../Validation/AccidentAndIncidentValidationSchema";
import PageContainer from "../../../../Core/PageContainer";
import FormContainer from "../../../../Core/Forms/FormContainer";
import MessageModal from "../../../../Core/Forms/MessageModal";
import LocationDetails from "./LocationDetails";
import PersonsDetails from "./PersonsDetails";
import InjuryDetails from "./InjuryDetails";
import OtherInformation from "./OtherInformation";
import Prevention from "./Prevention";
import CompletedBySection from "../../../../Core/Forms/CompletedBySection";
import Incident from "./Incident";
import { setCheckedValues } from "../../../Common/Functions/FormFunctions";

export default function AccidentAndIncident() {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const store = useSessionStore();
    const createIncident = location?.pathname === "/AccidentsIncidents/Add" ? true : false;

    const form = useForm({ mode: "onChange", resolver: yupResolver(AccidentAndIncidentValidationSchema) });
    const { handleSubmit, formState, getValues } = form;

    const [incident, setIncident] = useState({});
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [modal, setModal] = useState({show: false, header: "", message: ""});
    const [viewOnly, setViewOnly] = useState(location.state?.action && location.state.action === "view" ? true : false);

    useEffect(() => {

        if (params.id) {

            getIncident(params.id);
        } else if (createIncident) {

            setIncident({...Incident, InjuryTypes: [], InjuredBodyParts: []});
        }
    }, []);

    useEffect(() => {
        if (incident) form.reset(incident, {keepDirtyValues: true});
    }, [incident]);

    useEffect(() => {

        if (Object.keys(formState.errors).length === 0) {

            setSubmitEnabled(true);
        } else {

            setSubmitEnabled(false);
        }
    }, [formState]);

    const getIncident = async (id) => {

        let result = await store.AccidentsAndIncidentsService.GetIncident(id);

        if (result && result.Data) {

            let formData = {...result.Data};

            if (formData.SubmittedToAdminOn && store.Session.Role !== Roles.Admin) {
                setViewOnly(true);
            }

            formData.DateOfIncident = DateTime.fromISO(formData.DateTimeOfIncident).toFormat("yyyy-MM-dd");
            formData.TimeOfIncident = DateTime.fromISO(formData.DateTimeOfIncident).toFormat("HH:mm");
            formData.DateReported = DateTime.fromISO(formData.DateReported).toFormat("yyyy-MM-dd");
            formData.IncidentPersonDetails.DateOfBirth = DateTime.fromISO(formData.IncidentPersonDetails.DateOfBirth).toFormat("yyyy-MM-dd");

            setIncident(formData);
        }
    }

    const onSubmit = async () => {

        let incidentId = params.id ? params.id : null;

        if (!params.id) {
            incidentId = await onSave(true);
        } else {
            await onSave(true);
        }

        if (!incidentId) return;

        let result = await store.AccidentsAndIncidentsService.SubmitIncident(incidentId);

        if (result?.Success) {
            setModal({
                show: true,
                header: "Success!",
                message: "Accident & Incident submitted successfully",
                onClose: () => {onSuccess()}
            });
        } else {
            setModal({
                show: true,
                header: "Error!",
                message: result.Message,
                onClose: () => {setModal({show: false, header: "", message: ""})}
            });
        }
    };

    const onError = (errors, e) => console.log(errors, e);

    const onSave = async (submit = false) => {

        const defaultDate = "0001-01-01";
        const defaultTime = "00:00";
        
        const data = getValues();

        let result = null;
        
        if (data.DateReported === null || data.DateReported === "") data.DateReported = defaultDate;
        if (data.IncidentPersonDetails.DateOfBirth === null || data.IncidentPersonDetails.DateOfBirth === "") data.IncidentPersonDetails.DateOfBirth = defaultDate;
        if (data.DateOfIncident === null || data.DateOfIncident === "") data.DateOfIncident = defaultDate;
        if (data.TimeOfIncident === null || data.TimeOfIncident === "") data.TimeOfIncident = defaultTime;
        
        let saveIncident = {
            ...data,
            DateTimeOfIncident: DateTime.fromFormat(`${data.DateOfIncident} ${data.TimeOfIncident}`, "yyyy-MM-dd HH:mm", {zone: "gmt"}).toISO()
        }

        if (createIncident) {

            result = await store.AccidentsAndIncidentsService.CreateIncident(saveIncident);
        } else {

            result = await store.AccidentsAndIncidentsService.UpdateIncident(saveIncident);
        }

        if (!submit) {
            if (result?.Success) {

                setModal({
                    show: true,
                    header: "Success!",
                    message: "Accident & Incident saved successfully",
                    onClose: () => {onSuccess()}
                });
            } else {
                setModal({
                    show: true,
                    header: "Error!",
                    message: "An error occurred while saving the Accident & Incident",
                    onClose: () => {setModal({show: false, header: "", message: ""})}
                });
            }
        }

        return result?.Data;
    }

    const onSuccess = () => {
        setModal({show: false, header: "", message: ""});
        navigate(-1);
    }

    return <PageContainer title="Health & Safety" subtitle="Accident & Incident">
                {incident ?
                    <FormContainer formTitle="Accident & Incident" formNumber={incident && incident.IncidentNumber && `AI${incident.IncidentNumber}`} viewOnly={viewOnly}>
                        <FormProvider {...form}>
                            <Form className="text-black" onSubmit={handleSubmit(onSubmit, onError)}>
                                <LocationDetails
                                    disabled={viewOnly}
                                    dateReported={incident && incident.DateReported}
                                    dateTimeOfIncident={incident && incident.DateTimeOfIncident}
                                />
                                <PersonsDetails dateOfBirth={incident && incident.IncidentPersonDetails?.DateOfBirth} disabled={viewOnly}/>
                                <InjuryDetails
                                    disabled={viewOnly}
                                    bodyParts={incident && incident.InjuredBodyParts}
                                    setBodyPartsInjuryType={(index, value, property) => {setIncident(setCheckedValues(incident, index, value, property))}}
                                    injuryTypes={incident && incident.InjuryTypes}
                                />
                                <OtherInformation disabled={viewOnly} />
                                <Prevention disabled={viewOnly} />
                                <CompletedBySection
                                    submittedToAdminOn={incident?.SubmittedToAdminOn ? incident?.SubmittedToAdminOn : ""}
                                    submitEnabled={submitEnabled}
                                    onSave={onSave}
                                    disabled={viewOnly}
                                />
                            </Form>
                        </FormProvider>
                    </FormContainer> :
                    <div className="text-center pt-5 mt-5">
                        <Spinner variant="light"></Spinner>
                    </div>
                }
                <MessageModal modal={modal}/>
            </PageContainer>
}