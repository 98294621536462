import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";
import Sidebar from "./Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { getRoleName } from "../../Utilities/Enums";

export default function PageContainer({ title, subtitle, children }) {
	const store = useSessionStore();
	const navigate = useNavigate();

	return 	<Container fluid className="h-100 ps-0 overflow-hidden bg-primary">
				<Row className="h-100 overflow-hidden p-0">
					<Col xs={1} lg={2} className="h-100 overflow-hidden">
						<Sidebar></Sidebar>
					</Col>
					<Col className="h-100 overflow-scroll">
						<Row className="text-white p-3 py-4">
							<Col className="d-flex align-items-center ps-0">
								<h4>{title.toUpperCase()} - {subtitle}</h4>
							</Col>
							<Col className="d-flex justify-content-end align-items-center">
								<span>{ store.Session.Name } - { getRoleName(store.Session.Role) }</span>
								<FontAwesomeIcon icon={faUser} className="px-3"></FontAwesomeIcon>
								<Button variant="outline-light" onClick={ e => {store.Logout(); navigate("/")} }>Logout</Button>
							</Col>
						</Row>
						<Row className="pe-3">
							<Col>
								{ children }
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
}