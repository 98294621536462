import APIBase from "./APIBase";

/**
 * @typedef PrestartFormsService
 * @type {PrestartFormsService}
 */

/**
 * @typedef PrestartFormsService
 * @type {PrestartFormsService}
 */
export default class PrestartFormsService extends APIBase {
	/**
	 * @param {string} apiUrl
	 * @param { import("../Stores/SessionStore").SessionStore } sessionStore
	 **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "PrestartForms", sessionStore);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<PrestartFormsServiceVM> }
	 */	

	async GetPrestartForms(jobSheetId) {
		return this.Get(`GetPrestartForms?jobSheetsId=${encodeURIComponent(jobSheetId)}`);
	}

	async GetPrestartForm(prestartFormId) {
		return this.Get(`GetPrestartForm?prestartFormId=${encodeURIComponent(prestartFormId)}`);
	}
    
    async CreatePrestartForm(prestartForm) {
		return this.Post(`CreatePrestartForm`, prestartForm);
	}

	async UpdatePrestartForm(prestartForm) {
		return this.Post(`UpdatePrestartForm`, prestartForm);
	}

	async SubmitPrestartForm(prestartFormId) {
		return this.Post(`SubmitPrestartForm?prestartFormId=${encodeURIComponent(prestartFormId)}`);
	}
}