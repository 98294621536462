import { useEffect, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import InputField from '../../../Core/Forms/InputField';
import FormSection from '../../../Core/Forms/FormSection';
import CurrencyField from '../../../Core/Forms/CurrencyField';
import CheckboxField from '../../../Core/Forms/CheckboxField';

export default function SiteDetails({ quote, disabled}) {
    const { getValues, setValue, control } = useFormContext();
    const [useCustomerDetails, setUseCustomerDetails] = useState(false);
    const customerFields = useWatch({
        control,
        name: [
            "Customer.PrimaryContactFirstName",
            "Customer.PrimaryContactLastName",
            "Customer.PhoneNumber",
            "Customer.EmailAddress",
            "Customer.AddressLine1",
            "Customer.AddressLine2"
        ]
    });

    useEffect(() => {
        if (useCustomerDetails) {
            setSiteValues();
        } else {
            setSiteValues(true);
        }
    }, [useCustomerDetails])

    useEffect(() => {
        if (useCustomerDetails) {
            setSiteValues();
        }
    }, [customerFields])

    const setSiteValues = (clearValues = false) => {
        setValue("Site.ContactPersonFirstName", clearValues ? "" : getValues("Customer.PrimaryContactFirstName"));
        setValue("Site.ContactPersonLastName", clearValues ? "" : getValues("Customer.PrimaryContactLastName"));
        setValue("Site.PhoneNumber", clearValues ? "" : getValues("Customer.PhoneNumber"));
        setValue("Site.EmailAddress", clearValues ? "" : getValues("Customer.EmailAddress"));
        setValue("Site.AddressLine1", clearValues ? "" : getValues("Customer.AddressLine1"));
        setValue("Site.AddressLine2", clearValues ? "" : getValues("Customer.AddressLine2"));
    }

    return (        
        <FormSection title="Site Details">
            <Stack gap={3}>
                <Row>
                    <Col className="col-12 col-xl-6 d-print-none">
                        <CheckboxField label="Use Customer Details" name="SalesQuoteSite.UseCustomerDetails" disabled={disabled} onChange={e => setUseCustomerDetails(e.target.checked)}/>
                    </Col>
                </Row>
                <Row >
                    <Col className="col-12 col-xl-6">
                        <Stack gap={3}>
                            <InputField label="Site Contact Person First Name" name="Site.ContactPersonFirstName" disabled={disabled} required={!disabled}/>
                            <InputField className="mb-3 mb-xl-0" label="Phone Number" name="Site.PhoneNumber" disabled={disabled} required={!disabled}/>
                        </Stack>
                    </Col>
                    <Col className="col-12 col-xl-6">
                        <Stack gap={3}>
                            <InputField label="Site Contact Person Last Name" name="Site.ContactPersonLastName" disabled={disabled} required={!disabled}/>
                            <InputField label="Email" name="Site.EmailAddress" disabled={disabled} required={!disabled}/>
                        </Stack>
                    </Col>
                </Row>
                <Row >
                    <p className="fw-bold fs-6 mb-0">Site Address</p>
                </Row>
                <Row >
                    <Col className="col-12 col-xl-6">
                        <InputField className="mb-3 mb-xl-0" label="Address Line 1" name="Site.AddressLine1" disabled={disabled} required={!disabled}/>
                    </Col>
                    <Col className="col-12 col-xl-6">
                        <InputField label="Address Line 2" name="Site.AddressLine2" disabled={disabled} required={!disabled}/>
                    </Col>
                </Row>
                {quote && 
                    <>
                        <Row>
                            <Col className="col-4 col-xl-2">
                                <CheckboxField label="Cutting" name="SalesQuoteSite.Cutting" disabled={disabled}/>
                            </Col>
                            <Col className="col-4 col-xl-2">
                                <CheckboxField label="Flooring" name="SalesQuoteSite.Flooring" disabled={disabled}/>
                            </Col>
                            <Col className="col-4 col-xl-2">
                                <CheckboxField label="Chase Cutting" name="SalesQuoteSite.ChaseCutting" disabled={disabled}/>
                            </Col>
                            <Col className="col-6 col-xl-3 mt-3 mt-xl-0">
                                <InputField label="Days Allowed" name="SalesQuoteSite.DaysAllowed" disabled={disabled}/>
                            </Col>
                            <Col className="col-6 col-xl-3 mt-3 mt-xl-0">
                                <InputField label="Teams" name="SalesQuoteSite.Teams" disabled={disabled}/>
                            </Col>
                        </Row>
                        <Row >
                            <Col className="col-xl-6 offset-xl-6">
                                <CurrencyField
                                    name="SalesQuoteSite.TotalProductionCost"
                                    label="Total Production Cost"
                                    textAlignRight
                                    decimalPlaces={2}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                    </>
                }
            </Stack>
        </FormSection>
    )
}
