import { Row, Stack, Form, Col, Button } from "react-bootstrap";
import FormSection from "../../../../Core/Forms/FormSection";
import InputField from "../../../../Core/Forms/InputField";
import { ErrorMessage } from "@hookform/error-message";

const Details = ({ addFile, disabled, form }) => {

    const watchField = form.watch("PS3FormBase64");

    return (
        <FormSection title="Details">
            <Stack gap={(3)}>
                <Stack gap={3}>
                    <Row>
                        <p className="fw-bold fs-6">Site Address</p>
                    </Row>
                    <Row>
                        <Col className="col-12 col-xxl-6 mb-3 mb-xxl-0">
                            <InputField label="Address Line 1" name="Site.AddressLine1" disabled />
                        </Col>
                        <Col className="col-12 col-xxl-6">
                            <InputField label="Address Line 2" name="Site.AddressLine2" disabled />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12 col-xxl-6">
                            <InputField label="Job Sheet No." name="JobSheetNumber" value="" disabled />
                        </Col>                    
                    </Row>
                </Stack>
                <InputField label="Job Tasks Completed" name="JobTasksCompleted" defaultValue="" type="textarea" disabled={disabled}/>
                <InputField label="Products Required" name="ProductsRequired" defaultValue="" type="textarea" disabled={disabled}/>
                <Row className="d-print-none">
                    {!disabled &&
                        <>
                            <p className="">Assign PS3 Form (limit 2MB)</p>
                            <Col className="col-12 col-xxl-6">
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Control name="PS3Form" type="file" {...form.register("PS3Form", {onChange: (event) => {addFile(event)}})} disabled={disabled}/>                            
                                    <ErrorMessage
                                        errors={form.formState.errors}
                                        name={"PS3Form"}
                                        render={({ message }) => <Form.Text className="text-danger">{ message }</Form.Text>}
                                    />
                                </Form.Group>
                            </Col>
                        </>
                    }
                    {form.getValues("PS3FormName") !== null && <p className="">{form.getValues("PS3FormName")}</p>}
                    {watchField && watchField.length > 0 &&                        
                        <Col className="col-12">
                            <Col className="col-12 col-md-4 col-xxl-2">
                                    <Button
                                        variant="secondary"
                                        className="w-100"
                                        download={form.getValues("PS3FormName") ? form.getValues("PS3FormName") : ""}
                                        href={form.getValues("PS3FormBase64")}
                                    >
                                        Download
                                    </Button>
                            </Col>
                        </Col>
                    }
                </Row>
            </Stack>
        </FormSection>
    )
}

export default Details;