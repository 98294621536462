import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

export default function CheckboxField({ name, label, onChange, isChecked, disabled, required}) {
    
	const { register, watch, formState } = useFormContext();
	const watchField = watch(name);
	const errors = formState.errors;

	return  <>
        <Form.Check >
            <Form.Check.Input 
                type={"checkbox"}
                disabled={disabled}
                className={required && (watchField ? "" : "border-danger")}
                {...(isChecked && ({checked: isChecked}))}
                {...(name !== undefined && ({onChange: (e) => onChange && onChange(e)}))}
                onChange={(e) => onChange && onChange(e)}
                {...(name  && {...register(name, { required: required, onChange: onChange && onChange})})}
                />
            <Form.Check.Label>{label}</Form.Check.Label>
        </Form.Check>
        {
            errors && errors[name] &&
            <Form.Text className="text-danger">{ errors[name].message }</Form.Text>
        }
    </>
}