import * as yup from "yup";

const QualityAssuranceValidationSchema = yup.object().shape({
    Date: yup.date("Must be a valid date").nullable(true).transform(value => (value instanceof Date && !isNaN(value) ? value : null)).typeError("Must be a valid date"),
    JobTasksCompleted: yup.string(),
    ProductsRequired: yup.string(),
    DateAndItemsChecked: yup.string(),
    ProductAndDescriptionOfWorks: yup.string(),
    ResultsAndReadings: yup.string(),
    PS3Form: yup.mixed()
         .nullable()
         .test("FileTooBig", "File must be 2mb or smaller", (file) => testFileSize(file)),
    Photos: yup.array()
            .of(
                yup.object().shape({
                    Base64Data: yup.mixed()
                            .nullable()
                            .test("FileTooBig", "File must be 2mb or smaller", (file) => {
                                
                                if (file == null) return false;
                                
                                let base64str = file.substring(file.indexOf(',') + 1)
                                let decoded = atob(base64str);

                                if (decoded.length > 2 * 1024 * 1024) return false;

                                return true;
                            })
                })
            ),
    FormComplete: yup.boolean().oneOf([true], "This box must be checked to submit the form")
});

const testFileSize = (file) => {

    if (file && file[0] && file[0].size > 2 * 1024 * 1024) return false;
    
    return file ? file : true;
}

export default QualityAssuranceValidationSchema;