const Prestart = {
    Date: null,
    PreStartFormNumber: "",
    TeamLeaderId: "",
    CompletedBy: "",
    Hazards: [
    ],
    OtherHazards: [{
        Id: null,
        HazardName: "",
        HazardControls: ""
    }],
    TeamMembers: [{
        Id: null,
        PersonalTask: "",
        SignatureBase64: "",
        TeamMember: ""
    }]
}

export default Prestart;