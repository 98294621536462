import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

export default function SelectField({ name, label, options, value, disabled, required }) {
	const { register, watch, formState: { errors } } = useFormContext();
    const watchField = watch(name);
	const [selected, setSelected] = useState("");

    useEffect(() => {
        setSelected(value);
    }, [value]);

    const onchange = (event) => {
        setSelected(event.target.value);
    }

    return <Form.Group >
                <Form.Label>{label}</Form.Label>
                <Form.Select 
                    disabled={disabled}
                    value={selected}
                    {...register(name, { onChange: onchange })}
                    className={required && watchField === "" ? "border-danger" : ""}
                    required={required}
                >
                    {options.map((option, index) => {                        
                        return <option key={`${option.label}-${index}`} value={option.value} >{option.label}</option>
                    })}                    
                </Form.Select>
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <Form.Text className="text-danger">{ message }</Form.Text>}
                />
            </Form.Group>
}