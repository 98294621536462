import { Form } from "react-bootstrap";

export default function SignatureField({ label, type, rows }) {	

	return 	<Form.Group>
				{label &&
					<Form.Label>{ label }</Form.Label>
				}
					<Form.Control
						type={type}						
						className={"bg-white"}
                        disabled
						{...(type === "textarea" && ({rows: rows ? rows : 3}))}
						{...(type === "textarea" && ({as: "textarea"}))}
					/>
			</Form.Group>
}