import APIBase from "./APIBase";

/**
 * @typedef QAFormsService
 * @type {QAFormsService}
 */

/**
 * @typedef QAFormsService
 * @type {QAFormsService}
 */
export default class QAFormsService extends APIBase {
	/**
	 * @param {string} apiUrl
	 * @param { import("../Stores/SessionStore").SessionStore } sessionStore
	 **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "QAForms", sessionStore);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<QAFormsService> }
	 */	

	async GetQAForms(jobSheetId) {
		return this.Get(`GetQAForms?jobSheetId=${encodeURIComponent(jobSheetId)}`);
	}

	async GetQAForm(formId) {
		return this.Get(`GetQAForm?formId=${encodeURIComponent(formId)}`);
	}
    
    async CreateQAForm(qaForm) {		
		return this.Post(`CreateQAForm`, qaForm);
	}

	async UpdateQAForm(qaForm) {
		return this.Post(`UpdateQAForm`, qaForm);
	}

	async SubmitQAForm(formId) {
		return this.Post(`SubmitQAForm?formId=${encodeURIComponent(formId)}`);
	}
}