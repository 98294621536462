import APIBase from "./APIBase";

/**
 * @typedef VehicleInspectionsService
 * @type {VehicleInspectionsService}
 */
export default class VehicleInspectionsService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "VehicleInspections", sessionStore);
    }

    async GetVehicleInspections(search) {
        return this.Get(`GetVehicleInspections?search=${encodeURIComponent(search)}`)
    }

    async GetVehicleInspection(inspectionId) {
		return this.Get(`GetVehicleInspection?inspectionId=${encodeURIComponent(inspectionId)}`);
	}

    async CreateVehicleInspection(inspection) {
		return this.Post(`CreateVehicleInspection`, inspection);
	}

    async UpdateVehicleInspection(inspection) {
		return this.Post(`UpdateVehicleInspection`, inspection);
	}

    async SubmitVehicleInspection(inspectionId) {
		return this.Post(`SubmitVehicleInspection?inspectionId=${encodeURIComponent(inspectionId)}`);
	}
}