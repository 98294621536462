import * as yup from "yup";

const SalesQuoteValidationSchema = yup.object().shape({
    Status: yup.string(),
    HoursTaken: yup.string(),
    DateScheduled: yup
                    .date("Must be a valid date")
                    .nullable(true)
                    .transform(value => (value instanceof Date && !isNaN(value) ? value : null))
                    .typeError("Must be a valid date"),
                    SalesRepId: yup.string().required("Rep Name is required"),
    LeadFrom: yup.string().required("Lead From is required"),
    Date: yup.date("Date must be valid").required("Date is required").typeError("Date must be valid"),
    Customer: yup.object().shape({
        Name: yup.string().required("Customer is required"),
        PrimaryContactFirstName: yup.string().required("First Name is required"),
        PhoneNumber: yup
                    .string()
                    .required("Phone Number is required")
                    .test("PhoneNumberNotValid", "Phone number is not valid (Must start with '0' or '+64' followed by 7 - 9 digits)", (value) => value.replaceAll(" ", "")
                    .match(/^(\+64|0)[2-9]\d{7,9}$/)),
        PrimaryContactLastName: yup.string().required("Last Name is required"),
        EmailAddress: yup.string().required("Email is required").email("Email must be valid"),
        AddressLine1: yup.string().required("Address 1 is required"),
        City: yup.string().required("City is required"),
        AddressLine2: yup.string().required("Address 2 is required")
    }),
    Site: yup.object().shape({
        ContactPersonFirstName: yup.string().required("First Name is required"),
        PhoneNumber: yup
                    .string()
                    .required("Phone Number is required")
                    .test("PhoneNumberNotValid", "Phone number is not valid (Must start with '0' or '+64' followed by 7 - 9 digits)", (value) => value.replaceAll(" ", "")
                    .match(/^(\+64|0)[2-9]\d{7,9}$/)),
        ContactPersonLastName: yup.string().required("Last Name is required"),
        EmailAddress: yup.string().required("Email is required").email("Email must be valid"),
        AddressLine1: yup.string().required("Address 1 is required"),
        AddressLine2: yup.string().required("Address 2 is required")
    }),
    SalesQuoteSite: yup.object().shape({
        DaysAllowed: yup.string(),
        Teams: yup.string(),
        TotalProductionCost: yup.number().nullable(true).transform((_, val) => isNaN(val) ? null : Number(val))
    }),
    SpecialMachineryRequired: yup.string(),
    CustomerBrief: yup.string(),
    Photos: yup.array()
            .of(
                yup.object().shape({
                    Base64Data: yup.mixed()
                            .nullable()
                            .test("FileTooBig", "File must be 2mb or smaller", (file) => {
                                
                                if (file == null) return false;
                                
                                let base64str = file.substring(file.indexOf(',') + 1)
                                let decoded = atob(base64str);

                                if (decoded.length > 2 * 1024 * 1024) return false;

                                return true;
                            })
                })
            ),
    FormComplete: yup.boolean().oneOf([true], "This box must be checked to submit the form")
});

export default SalesQuoteValidationSchema;