import { Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";

export default function SearchField({ onChange, onClick }) {
    return (
        <InputGroup className="mb-3">
            <Form.Control
                placeholder="Type here to search"
                onChange={ e => onChange(e)} 
            />
            <Button variant="secondary" id="button-addon2" onClick={onClick}>
                <FontAwesomeIcon style={{ cursor: "pointer" }} className="text-white" icon={ faSearch } ></FontAwesomeIcon>
            </Button>
      </InputGroup>
    )
}