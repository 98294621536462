import { Col, Stack } from 'react-bootstrap';
import { Roles } from '../../../../../Utilities/Enums';
import DateField from '../../../../Core/Forms/DateField';
import InputField from '../../../../Core/Forms/InputField';
import FormSection from '../../../../Core/Forms/FormSection';
import UserRoleSelect from '../../../Common/Components/UserRoleSelect';

const SalesQuoteDetails = ({ date, salesRep, disabled }) => {

    return (
        <FormSection title="Estimate Details">
            <Col className="col-12 col-md-6 mb-3 mb-md-0">
                <Stack gap={3}>
                    <UserRoleSelect label="Rep Name" name="SalesRepId" value={salesRep} disabled={disabled} role={Roles.SalesRep} required={!disabled}/>
                    <InputField label="Lead From" name="LeadFrom" disabled={disabled} required={!disabled}/>
                </Stack>
            </Col>
            <Col>
                <DateField label="Date" name="Date" value={date} disabled={disabled} required={!disabled}/>
            </Col>
        </FormSection>
    )
}

export default SalesQuoteDetails;