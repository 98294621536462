import * as yup from "yup";

const getUserSchema = (newUser) => {

    const UserValidationSchema = {
        FirstName: yup.string().required("Name is required"),
        LastName: yup.string().required("Surname is required"),
        Email: yup.string().email("Email must be a valid email").required("Email is required"),
        ContactNumber: yup
                    .string()
                    .required("Contact Number is required")
                    .test("ContactNumberNotValid", "Contact number is not valid (Must start with '0' or '+64' followed by 7 - 9 digits)", (value) => value.replaceAll(" ", "")
                    .match(/^(\+64|0)[2-9]\d{7,9}$/)),
        Role: yup.string().required("Role is required"),
        FormComplete: yup.boolean().oneOf([true], "This box must be checked to submit the form"),
    };

    if (newUser) {
        
        return yup.object().shape({
            ...UserValidationSchema,
            Password: yup.string().required("Password is required").test("len", "Password must be between 8 and 16 characters long.", val => val.length >= 8 && val.length <= 16).test("special", "Password must contain at least one special character", val => /[!@#$%^&*(),.?":{}|<>]/.test(val)).test("upper", "Password must contain at least one uppercase character", val => /[A-Z]/.test(val)).test("lower", "Password must contain at least one lowercase character", val => /[a-z]/.test(val)).test("number", "Password must contain at least one number", val => /[0-9]/.test(val)),
            ConfirmPassword: yup.string().required("Confirm password is required").oneOf([yup.ref('Password'), null], 'Passwords must match')
        });
    } else {
        
        return yup.object().shape({
            ...UserValidationSchema,
            Password: yup.string().test("len", "Password must be between 8 and 16 characters long.", (val, context) => {
                
                if (val.length > 0) {
        
                    if (val.length < 8 || val.length > 16) {
                        return context.createError({ path: "Password", message: "Password must be between 8 and 16 characters long." });
                    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(val)) {
                        return context.createError({ path: "Password", message: "Password must contain at least one special character" });
                    } else if (!/[A-Z]/.test(val)) {
                        return context.createError({ path: "Password", message: "Password must contain at least one uppercase character" });
                    } else if (!/[a-z]/.test(val)) {
                        return context.createError({ path: "Password", message: "Password must contain at least one lowercase character" });
                    } else if (!/[0-9]/.test(val)) {
                        return context.createError({ path: "Password", message: "Password must contain at least one number" });
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            }),
            ConfirmPassword: yup.string().oneOf([yup.ref('Password'), null], 'Passwords must match')
        });
    }
}

export default getUserSchema;