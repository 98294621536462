import { useState, useEffect } from 'react';
import { Col, Row, Stack, Form } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import { getHazards, getHazard } from "../../../../../Utilities/Enums";
import FormSection from '../../../../Core/Forms/FormSection';
import InputField from '../../../../Core/Forms/InputField';
import PlusButton from '../../../../Core/Forms/PlusButton';
import MinusButton from '../../../../Core/Forms/MinusButton';

const Hazards = ({ hazards, disabled }) => {
    const { setValue } = useFormContext();

    const [fixedHazards, setFixedHazards] = useState(null);
    const [otherHazards, setOtherHazards] = useState(null);

    useEffect(() => {
        
        let hazardLabels = Object.keys(getHazards());
        let newOtherHazards = [];
        let newFixedhazards = [];
        
        for (let i = 0; i < hazardLabels.length; i++) {

            let hazardName = getHazard(getHazards()[hazardLabels[i]]);
            let hazardObj = {
                HazardName: hazardName,
                HazardControls: null,
                Id: null
            }

            for (let j = 0; j < hazards?.length; j++) {

                if (hazardName === hazards[j].HazardName) {

                    hazardObj = {
                        Id: hazards[j].Id,
                        HazardName: hazards[j].HazardName,
                        HazardControls: hazards[j].HazardControls
                    }
                    break;
                }
            }
            
            newFixedhazards.push(hazardObj);
        }

        newOtherHazards = hazards?.filter(hazard => !Object.values(getHazards()).map(h => getHazard(h)).includes(hazard.HazardName));
        
        setFixedHazards(newFixedhazards);
        setOtherHazards(newOtherHazards);
    }, [hazards]);

    useEffect(() => {

        if (!fixedHazards) return;

        setValue("Hazards", fixedHazards);
    }, [fixedHazards]);

    useEffect(() => {

        if (!otherHazards) return;
        
        otherHazards.forEach((hazard, index) => {

            if (hazard.HazardName === "") return;

            setValue(`Hazards.${fixedHazards.length + index}.HazardName`, hazard.HazardName);
            setValue(`Hazards.${fixedHazards.length + index}.HazardControls`, hazard.HazardControls);
            setValue(`Hazards.${fixedHazards.length + index}.Id`, hazard.Id);
        });
    }, [otherHazards]);

    return (
        <FormSection title="Hazards">
            <Row>
                <Col className="col-3 d-none d-xxl-block">
                    <p>Type Identified</p>
                </Col>
                <Col className="col-3 ps-4 d-none d-xxl-block">
                    <p>Controls</p>
                </Col>
            </Row>
            <Stack gap={2}>
                {fixedHazards && fixedHazards.map((hazard, index) =>
                    <Hazard
                        label={hazard.HazardName}
                        index={index}
                        disabled={disabled}
                    />
                )}
                {otherHazards && otherHazards.map((other, index) =>
                    <OtherHazard
                        hazard={other}
                        index={index}
                        disabled={disabled}
                    />
                )}
                {!disabled &&
                    <Row>
                        <Col className="col-6 d-flex justify-content-start">
                            <Stack direction="horizontal">
                            <PlusButton
                                onClick={
                                    () => {

                                        let newOtherHazards = [...otherHazards]

                                        newOtherHazards.push({
                                            Id: null,
                                            HazardName: "",
                                            HazardControls: ""
                                        })

                                        setOtherHazards(newOtherHazards);
                                    }
                                }
                                disabled={disabled}
                            />
                            {
                                <MinusButton
                                    onClick={() => {

                                        let newOtherHazards = [...otherHazards];

                                        newOtherHazards.pop();

                                        setOtherHazards(newOtherHazards);
                                    }}
                                disabled={disabled}/>
                            }
                            </Stack>
                        </Col>
                    </Row>
                }
            </Stack>
        </FormSection>
    )
}

const Hazard = ({label, index, disabled}) => {
    const { setValue } = useFormContext();
    
    const checked = useWatch({name: `Hazards.${index}.HazardControls`});

    const toggleHazard = (event, index) => {
            
        setValue(`Hazards.${index}.HazardControls`, event.target.checked ? "" : null);
    }

    useEffect(() => {
        
        if (checked) {
            setValue(`Hazards.${index}.HazardName`, label);
        }
    }, [checked]);

    return (
        <Row>
            <Col className="col-12 col-xxl-3 d-flex align-items-center mb-3 mb-xxl-0">
                <Form.Check >
                    <Form.Check.Input 
                        type={"checkbox"}
                        disabled={disabled}                            
                        checked={checked !== null && checked !== undefined}
                        name={`Hazards.${index}.HazardName`}
                        onChange={(event) => toggleHazard(event, index)}
                    />
                    <Form.Check.Label>{label}</Form.Check.Label>
                </Form.Check>
            </Col>
            <Col className="col-12 col-xxl-9 mb-2 mb-xxl-0">
                <InputField
                    type="textarea"
                    rows={2}
                    name={`Hazards.${index}.HazardControls`}
                    required={checked !== null}
                    disabled={disabled}
                />
            </Col>
        </Row>
    )
}

const OtherHazard = ({index, disabled}) => {   
    
    const hazardName = useWatch({name: `Hazards.${index + 16}.HazardName`});

    return (
        <Row className="">
                <Col className="col-12 col-xxl-3 align-self-start mb-2 mb-xxl-0">
                    <InputField
                        name={`Hazards.${index + 16}.HazardName`}
                        placeholder="Other - type here"
                        disabled={disabled}
                    />
                </Col>
                <Col className=" mb-3 mb-xxl-0">
                    <InputField
                        name={`Hazards.${index + 16}.HazardControls`}
                        type="textarea"
                        rows={2}
                        required={hazardName !== ""}
                        disabled={disabled}
                    />
                </Col>
            </Row>
    )
}

export default Hazards;