import { Stack, Row, Col, Form } from 'react-bootstrap';
import FormSection from "../../../../Core/Forms/FormSection";
import InputField from "../../../../Core/Forms/InputField";
import { Controller, useFormContext } from "react-hook-form";

const OtherInformation = ({ disabled }) => {
    const { control, formState: { errors } } = useFormContext();

    return (
        <FormSection title="Other Information">
            <Stack gap={3}>
                <Row>
                    <Col className="col-12 col-xxl-6 mb-3 mb-xxl-0">
                        <InputField
                            label="Property or material damaged (if any)"
                            name="IncidentInformation.PropertyDamaged"
                            type="textarea"
                            disabled={disabled}                                            
                        />
                    </Col>
                    <Col className="col-12 col-xxl-6">
                        <InputField
                            label="Object/Substance causing damage"
                            name="IncidentInformation.CauseOfDamage"
                            type="textarea"
                            disabled={disabled}                                            
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputField
                            label="Nature of damage"
                            name="IncidentInformation.NatureOfDamage"
                            type="textarea"
                            disabled={disabled}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputField
                            label="The accident/incident : Describe what happened."
                            name="IncidentInformation.IncidentDescription"
                            type="textarea"
                            rows={5}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputField label="What caused the accident?" name="IncidentInformation.CauseOfAccident" type="textarea" rows={5} disabled={disabled}/>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 col-xxl-6 border-end border-black mb-3 mb-xxl-0">
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            name={"IncidentInformation.Seriousness"}
                            render={({ field: { onChange, value } }) => (
                                <Stack gap={3}>
                                    <Col>
                                        <Form.Label>How serious could it have been? (select one)</Form.Label>
                                    </Col>
                                    <Col className="col-4">
                                        <Form.Check
                                            type="radio"
                                            label={"Minor"}
                                            value={"Minor"}
                                            {...{checked: value === "Minor" ? true : false}}
                                            onChange={onChange}
                                            disabled={disabled}
                                        />
                                    </Col>
                                    <Col className="col-4">
                                        <Form.Check
                                            type="radio"
                                            label={"Serious"}
                                            value={"Serious"}
                                            {...{checked: value === "Serious" ? true : false}}
                                            onChange={onChange}
                                            disabled={disabled}
                                        />
                                    </Col>
                                    <Col className="col-4">
                                        <Form.Check
                                            type="radio"
                                            label={"Very Serious"}
                                            value={"Very Serious"}
                                            {...{checked: value === "Very Serious" ? true : false}}
                                            onChange={onChange}
                                            disabled={disabled}                                                        
                                        />
                                    </Col>                                                
                                </Stack>
                            )}
                        />
                        {
                            errors && errors.HowSerious ?
                                <Form.Text className="text-danger">{"You must select an option" }</Form.Text> :
                                null
                        }
                    </Col>
                    <Col className="col-12 col-xxl-6">
                        <Controller
                            control={control}
                            rules={{
                                required: true,
                            }}
                            name={"IncidentInformation.ChanceOfRepeat"}
                            render={({ field: { onChange, value } }) => (
                                <Stack gap={3}>
                                    <Col>
                                        <Form.Label>What is the chance of it happening again? (select one)</Form.Label>
                                    </Col>
                                    <Col className="col-4">
                                        <Form.Check
                                            type="radio"
                                            label={"Not Likely"}
                                            value={"Not Likely"}
                                            {...{checked: value === "Not Likely" ? true : false}}
                                            onChange={onChange}
                                            disabled={disabled}
                                            />
                                    </Col>
                                    <Col className="col-4">
                                        <Form.Check
                                            type="radio"
                                            label={"Somewhat Likely"}
                                            value={"Somewhat Likely"}
                                            {...{checked: value === "Somewhat Likely" ? true : false}}
                                            onChange={onChange}
                                            disabled={disabled}
                                            />
                                    </Col>
                                    <Col className="col-4">
                                        <Form.Check
                                            type="radio"
                                            label={"Very Likely"}
                                            value={"Very Likely"}
                                            {...{checked: value === "Very Likely" ? true : false}}
                                            onChange={onChange}
                                            disabled={disabled}
                                            />
                                    </Col>                                                
                                </Stack>
                            )}
                        />
                        {
                            errors && errors.ChanceOfHappeningAgain ?
                                <Form.Text className="text-danger">{"You must select an option" }</Form.Text> :
                                null
                        }
                    </Col>
                </Row>
            </Stack>
        </FormSection>
    )
}

export default OtherInformation;