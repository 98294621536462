import { useFormContext } from "react-hook-form";
import { Col, Stack } from "react-bootstrap";
import FormSection from "../../../../Core/Forms/FormSection";
import SelectField from "../../../../Core/Forms/SelectField";
import NumberField from "../../../../Core/Forms/NumberField";
import InputField from "../../../../Core/Forms/InputField";
import DateField from "../../../../Core/Forms/DateField";

const DriverAndVehicleInformation = ({ disabled }) => {
    const { getValues } = useFormContext();

    const employeeOptions = [
        {label: "Please Select", value: ""},
        {label: "Aaron MacDonald", value: "Aaron MacDonald"},
        {label: "Alfie Julian", value: "Alfie Julian"},
        {label: "Allan Chavez", value: "Allan Chavez"},
        {label: "Chito Aceron", value: "Chito Aceron"},
        {label: "Cris Baldo JR", value: "Cris Baldo JR"},
        {label: "Ed Gibson", value: "Ed Gibson"},
        {label: "Elden Cambe", value: "Elden Cambe"},
        {label: "Ernie Abellera", value: "Ernie Abellera"},
        {label: "Jeff Coe", value: "Jeff Coe"},
        {label: "Jo Mar Garcia", value: "Jo Mar Garcia"},
        {label: "John Gamble", value: "John Gamble"},
        {label: "Julius Malfarta", value: "Julius Malfarta"},
        {label: "Jun Uehara", value: "Jun Uehara"},
        {label: "Kanye Priest", value: "Kanye Priest"},
        {label: "Leroy Aitcheson", value: "Leroy Aitcheson"},
        {label: "Luke (Makarau) Manners-Coe", value: "Luke (Makarau) Manners-Coe"},
        {label: "Luke Mara Sukanaveita", value: "Luke Mara Sukanaveita"},
        {label: "Monte Pahulu", value: "Monte Pahulu"},
        {label: "Nathan Bourne", value: "Nathan Bourne"},
        {label: "Richard Pahulu", value: "Richard Pahulu"},
        {label: "Rodrigo Aguila", value: "Rodrigo Aguila"},
        {label: "Thomas Heard", value: "Thomas Heard"},
        {label: "Tomas Semic", value: "Tomas Semic"}
    ];

    const registrationNumber = [
        {label: "Please Select", value: ""},
        {label: "BZB255", value: "BZB255"},
        {label: "CPG904", value: "CPG904"},
        {label: "EFP776", value: "EFP776"},
        {label: "FAF162", value: "FAF162"},
        {label: "FRE29", value: "FRE29"},
        {label: "FWK368", value: "FWK368"},
        {label: "GHG320", value: "GHG320"},
        {label: "GZG875", value: "GZG875"},
        {label: "HUE530", value: "HUE530"},
        {label: "HUE530", value: "HUE530"},
        {label: "JEA279", value: "JEA279"},
        {label: "JJL830", value: "JJL830"},
        {label: "KSK627", value: "KSK627"},
        {label: "KSK627", value: "KSK627"},
        {label: "LYN893", value: "LYN893"},
        {label: "PML738", value: "PML738"}
    ];

    return (
        <FormSection title="Driver and Vehicle Information">
            <Col className="col-12 col-xxl-6">
                <Stack gap={3}>
                    <SelectField label="Driver/Employee Name" name="EmployeeName" options={employeeOptions} disabled={disabled} required={!disabled}/>
                    <SelectField label="Registration Number" name="RegistrationNumber" options={registrationNumber} disabled={disabled} required={!disabled}/>
                    <NumberField

                        label="Speed Odometer Reading"
                        name="OdometerReading"
                        disabled={disabled}
                        value={getValues("OdometerReading")}
                        required={!disabled}
                    />
                    <DateField label="WOF/COF Expiry Date" name="COFExpiryDate" disabled={disabled}/>
                </Stack>
            </Col>
            <Col className="col-12 col-xxl-6">
                <Stack gap={3}>
                    <DateField label="Date Completed" name="DateCompleted" disabled={disabled} required={!disabled}/>
                    <DateField label="Registration Expiry Date" name="RegistrationExpiryDate" disabled={disabled} required={!disabled}/>
                    <InputField label="Next Service Due" name="NextServiceDue" type="date" disabled={disabled}/>
                    <NumberField label="Hubo Reading Current" name="HuboReadingCurrent" value={getValues("HuboReadingCurrent")} disabled={disabled}/>
                </Stack>
            </Col>
        </FormSection>
    )
}

export default DriverAndVehicleInformation;