import { faEdit, faEye, faEllipsisV, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { Row, Col, Stack } from "react-bootstrap";
import ClickIcon from "../../../Core/ClickIcon";

export default function Actions({ edit, view, del, moreActions }) {

	return 	<>
				<Row className="d-flex justify-content-center">
					<Col xs={8} className="d-flex justify-content-center align-items-center">
						<Stack gap={2} direction="horizontal">
							{edit && <ClickIcon onClick={() => edit.action()} icon={ faEdit } title={edit.title}/>}
							{view && <ClickIcon onClick={ () => view.action()} icon={ faEye } title={view.title} />}
							{del && <ClickIcon onClick={ () => del.action()} icon={ faTrash } title={view.title} />}
							{moreActions && <ClickIcon onClick={ () => moreActions?.action && moreActions.action() } icon={ faEllipsisV } title="More Actions" />}
						</Stack>
					</Col>
				</Row>
			</>
}