import { Col, Stack } from 'react-bootstrap';
import { DateTime } from "luxon";
import FormSection from '../../../../Core/Forms/FormSection';
import InputField from '../../../../Core/Forms/InputField';
import DateField from '../../../../Core/Forms/DateField';

const LocationDetails = ({ dateReported, dateTimeOfIncident, disabled }) => {

    return (
        <FormSection title="Location Details">
            <Col className="col-12 col-xxl-6 mb-3 mb-xxl-0">
                <Stack gap={3}>
                    <InputField label="Name of Organisation" name="NameOfOrganisation" disabled={disabled} required={!disabled}/>
                    <InputField label="Branch / Department" name="Branch" disabled={disabled} required={!disabled}/>
                    <InputField label="Location" name="Location" disabled={disabled} required={!disabled}/>
                </Stack>
            </Col>
            <Col className="col-12 col-xxl-6">
                <Stack gap={3}>
                    <DateField label="Date of Accident/Incident" name="DateOfIncident" value={dateTimeOfIncident && DateTime.fromISO(dateTimeOfIncident).toFormat("yyyy-MM-dd")} disabled={disabled} required={!disabled}/>
                    <DateField label="Time of Accident/Incident" name="TimeOfIncident" value={dateTimeOfIncident && DateTime.fromISO(dateTimeOfIncident).toFormat("HH:mm")} type="time" disabled={disabled} required={!disabled}/>                
                    <DateField label="Date Reported" name="DateReported" value={dateReported && dateReported} disabled={disabled} required={!disabled}/>
                </Stack>
            </Col>
        </FormSection>
    )
}

export default LocationDetails;