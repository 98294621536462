const setCheckedValues = (model, index, value, property ) => {
            
    let newModel = {...model};

    if (value) {

        if (!newModel[property].includes(index)) {
            newModel[property].push(index);
        }
    } else {
        const indexToRemove = newModel[property].indexOf(index);

        if (indexToRemove !== -1) {
            newModel[property].splice(indexToRemove, 1);
        }
    }

    return newModel;
}

export { setCheckedValues };