import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import SearchField from "../../../../Core/Forms/SearchField";

export default function Headerbar({ onSearchClicked }) {

    const [search, setSearch] = useState("");

    return <Row>
        <Col className="col-12 col-lg-4 mb-3 mb-lg-0">
            <SearchField label="Search" name="search" value={search} onChange={e => setSearch(e.target.value)} onClick={e => onSearchClicked(search)} disabled={false} />
        </Col>
        <Col className="col-12 col-lg-4 offset-lg-4 col-xl-3 offset-xl-5 col-xxl-2 offset-xxl-6">
            <a href="#/AccidentsIncidents/Add" className="btn btn-secondary w-100">Create Accident & Incident</a>
        </Col>
    </Row>
}