import APIBase from "./APIBase";

/**
 * @typedef AccidentsAndIncidentsService
 * @type {AccidentsAndIncidentsService}
 */
export default class AccidentsAndIncidentsService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore){
        super(apiUrl + "Incidents", sessionStore);
    }

    async GetIncidents(search){
        return this.Get(`GetIncidents?search=${encodeURIComponent(search)}`);
    }

    async GetIncident(incidentId) {
		return this.Get(`GetIncident?incidentId=${encodeURIComponent(incidentId)}`);
	}

    async CreateIncident(incident) {
		return this.Post(`CreateIncident`, incident);
	}

    async UpdateIncident(incident) {
		return this.Post(`UpdateIncident`, incident);
	}

    async SubmitIncident(incidentId) {
		return this.Post(`SubmitIncident?incidentId=${encodeURIComponent(incidentId)}`);
	}
}