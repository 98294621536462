import * as yup from "yup";

const AccidentAndIncidentValidationSchema = yup.object().shape({
    NameOfOrganisation: yup.string().required("Name of Organisation is required"),
    Branch: yup.string().required("Branch/Department is required"),
    Location: yup.string().required("Location is required"),
    DateOfIncident: yup.date("Must be a valid date").typeError("Must be a valid date").required("Date of Accident/Incident is required"),
    TimeOfIncident: yup.string().required("Time of Accident/Incident is required"),
    DateReported: yup.date("Must be a valid date").typeError("Must be a valid date"),
    IncidentPersonDetails: yup.object().shape({
        Name: yup.string().required("Name is required"),
        PhoneNumber: yup
                        .string()
                        .required("Phone Number is required")
                        .test("PhoneNumberNotValid", "Phone number is not valid (Must start with '0' or '+64' followed by 7 - 9 digits)", (value) => value.replaceAll(" ", "")
                        .match(/^(\+64|0)[2-9]\d{7,9}$/)),
        DateOfBirth: yup.date("Must be a valid date").typeError("Must be a valid date").required("Date of Birth is required"),
        LengthOfEmployment: yup.string(),
        Address: yup.string().required("Address is required"),
    }),
    IncidentInformation: yup.object().shape({
        Seriousness: yup.string().required("Must select an option"),
        ChanceOfRepeat: yup.string().required("Must select an option"),
    }),
    FormComplete: yup.boolean().oneOf([true], "This box must be checked to submit the form")
});

export default AccidentAndIncidentValidationSchema;