import { Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

export default function PlusButton({onClick, disabled}) {
    
    return (
        <Col className="col-6 mx-1 d-print-none">
            <Button size="sm" variant="secondary" onClick={() => onClick()} {...(disabled && ({ disabled }))}>
                <FontAwesomeIcon icon={faPlus} size="xl"  />
            </Button>
        </Col>
    )
}