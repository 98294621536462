import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import SearchField from "../../../../Core/Forms/SearchField";
import TeamLeaderFilter from "../../../Common/Components/TeamLeaderFilter";

/**
 * @param {Object} HeaderBarProps 
 * @param {(search: string, status: number) => void} HeaderBarProps.onFilterClicked 
 */
export default function Headerbar({ onFilterClicked, onTeamLeaderSelected }) {
	const [search, setSearch] = useState("");
	const [status, setStatus] = useState("");

	return 	<Row>
				<Col className="col-12 col-lg-4 mb-3 mb-lg-0">
					<SearchField label="Search" name="search" value={search} onChange={e => setSearch(e.target.value)} onClick={e => onFilterClicked(search, status)} disabled={false} />
				</Col>
				{/* Temporarily hidden until new development */}
				<Col className="col-12 col-lg-4 mb-3 mb-lg-0 d-none">
					<Form.Select placeholder="Status" value={status} onChange={ e => {setStatus(e.target.value); onFilterClicked(search, e.target.value);} }>
						<option value=""></option>
						<option value="1">Current</option>
						<option value="2">Completed</option>
					</Form.Select>
				</Col>
				<Col className="col-12 col-lg-4 mb-3 mb-lg-0">
					<TeamLeaderFilter status={status} onTeamLeaderSelected={onTeamLeaderSelected} />
				</Col>
			</Row>
}