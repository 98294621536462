import { Col, Row, Stack, Form } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import FormSection from '../../../../Core/Forms/FormSection';
import InputField from '../../../../Core/Forms/InputField';
import PlusButton from '../../../../Core/Forms/PlusButton';
import MinusButton from '../../../../Core/Forms/MinusButton';

const Team = ({disabled}) => {
    const { control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control: control,
        name: "TeamMembers",
    });

    return (
        <FormSection title="Team">
            <Stack className="d-xxl-block d-none">
                <Row>
                    <Col className="col-4">
                        <Form.Label>Team Member</Form.Label>
                    </Col>
                    <Col className="col-4">
                        <Form.Label>Personal Task</Form.Label>
                    </Col>
                    <Col className="col-4">
                        <Form.Label>Signature</Form.Label>
                    </Col>
                </Row>
            </Stack>
            <Stack gap={3}>
                {fields.map((t, index) =>
                    <Row key={`${t}-${index}`}>
                        <Col className="col-12 col-xxl-4 mb-3 mb-xxl-0">
                            <InputField
                                label="Team Member"
                                labelClass="d-block d-xxl-none"
                                name={`TeamMembers[${index}].TeamMember`}
                                value={t.teamMember}
                                type="textarea"
                                rows={2}
                                disabled={disabled}
                            />
                        </Col>
                        <Col className="col-12 col-xxl-4 mb-3 mb-xxl-0">
                            <InputField
                                label="Personal Task"
                                labelClass="d-block d-xxl-none"
                                name={`TeamMembers[${index}].PersonalTask`}
                                value={t.personalTask}
                                type="textarea"
                                rows={2}
                                disabled={disabled}
                            />
                        </Col>
                        <Col className="col-12 col-xxl-4 mb-3 mb-xxl-0">
                            <InputField
                                label="Signature"
                                labelClass="d-block d-xxl-none"
                                name={`TeamMembers[${index}].SignatureBase64`}
                                value={t.signatureBase64}
                                type="textarea"
                                rows={2}
                                disabled={disabled}
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col className="col-6 d-flex justify-content-start">
                        <Stack direction="horizontal">
                            {!disabled &&
                                <PlusButton                                        
                                    onClick={
                                        () => {
                                            append({
                                                Id: null,
                                                PrestartFormId: null,
                                                TeamMember: "",
                                                PersonalTask: "",
                                                SignatureBase64: ""
                                            })
                                        }
                                    }
                                    disabled={disabled}
                                />
                            }
                            {
                                !disabled && fields.length > 1 &&
                                <MinusButton onClick={() => {remove(-1)}} disabled={disabled}/>
                            }
                        </Stack>
                    </Col>
                </Row>
            </Stack>
        </FormSection>
    )
}

export default Team;