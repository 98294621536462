import { Form } from "react-bootstrap";
import { useFormContext, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { NumericFormat } from "react-number-format";

export default function CurrencyField({label, labelClass, name, value, textAlignRight, decimalPlaces, onChange, disabled, required }) {
    const { control, setValue, watch, formState: { errors }, getValues } = useFormContext();
    const watchField = watch(name);
    
    let className = textAlignRight ? "text-end" : "";

    return (
        <Form.Group>
            {label &&
                <Form.Label className={labelClass ? labelClass : ""}>{ label }</Form.Label>
            }
            <Controller
                name={name}
                control={control}
                rules={{
                    required: true,
                }}
                value={value}
                render={({ field }) => {
                    return (
                        <NumericFormat 
                            thousandSeparator=","
                            decimalScale={decimalPlaces}
                            fixedDecimalScale={true}
                            prefix={"$ "}
                            value={getValues(name)}
                            name={field.name}
                            customInput={ Form.Control }
                            className={className + (required && watchField === "" ? "border-danger" : "")}
                            onValueChange = {(value) => {
                                setValue(name, value.floatValue);
                                onChange && onChange(value.floatValue);
                                field.onChange(value.floatValue);
                            }}
                            disabled={disabled}
                        />
                    )}
                }
            />
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => <Form.Text className="text-danger">{ message }</Form.Text>}
            />
        </Form.Group>
    );
}