import { Col, Row, Stack } from 'react-bootstrap';
import { Form } from "react-bootstrap";
import FormSection from '../../../../Core/Forms/FormSection';
import { LeaderRequirements, getLeaderRequirementsLabel } from "../../../../../Utilities/Enums";

const SiteLeaderRequirements = ({ requirements, setSiteLeaderRequirements, disabled }) => {
    
    const CheckBox = ({label, id}) => {
        return (
            <Form.Check >
                <Form.Check.Input
                    type={"checkbox"}
                    disabled={disabled}
                    checked={ requirements?.indexOf(id) > -1 }
                    onChange={ e => setSiteLeaderRequirements(id, e.target.checked) }
                />
                <Form.Check.Label>{label}</Form.Check.Label>
            </Form.Check>
        )
    }

    return (
        <FormSection title="Site Leader Requirements">
            <Stack gap={3}>
                <Row>
                    <Col>
                        <Stack gap={3}>
                            <CheckBox label={getLeaderRequirementsLabel(LeaderRequirements.SetupAndRunJob)} id={LeaderRequirements.SetupAndRunJob}/>
                            <CheckBox label={getLeaderRequirementsLabel(LeaderRequirements.WorkInTradesmanlikeMAnner)} id={LeaderRequirements.WorkInTradesmanlikeMAnner}/>
                            <CheckBox label={getLeaderRequirementsLabel(LeaderRequirements.PS3Required)} id={LeaderRequirements.PS3Required}/>
                            <CheckBox label={getLeaderRequirementsLabel(LeaderRequirements.FillInAllWork)} id={LeaderRequirements.FillInAllWork}/>
                            <CheckBox label={getLeaderRequirementsLabel(LeaderRequirements.QARequired)} id={LeaderRequirements.QARequired}/>
                        </Stack>
                    </Col>
                    <Col>
                        <Stack gap={3}>
                            <CheckBox label={getLeaderRequirementsLabel(LeaderRequirements.CheckCorrectProduct)} id={LeaderRequirements.CheckCorrectProduct}/>
                            <CheckBox label={getLeaderRequirementsLabel(LeaderRequirements.RecordColoursEtc)} id={LeaderRequirements.RecordColoursEtc}/>
                            <CheckBox label={getLeaderRequirementsLabel(LeaderRequirements.BeOnTime)} id={LeaderRequirements.BeOnTime}/>
                            <CheckBox label={getLeaderRequirementsLabel(LeaderRequirements.ReportToManagement)} id={LeaderRequirements.ReportToManagement}/>
                        </Stack>
                    </Col>
                </Row>
            </Stack>
        </FormSection>
    )
}

export default SiteLeaderRequirements;