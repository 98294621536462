import { makeAutoObservable } from "mobx";
import { createContext, useContext } from "react";
import SalesQuotesService from "../Services/SalesQuotesService";
import JobSheetsService from "../Services/JobSheetsService";
import UsersService from "../Services/UsersService";
import AccidentsAndIncidentsService from "../Services/AccidentsAndIncidentsService";
import VehicleInspectionsService from "../Services/VehicleInspectionsService";
import RemediationReportsService from "../Services/RemediationReportsService";
import PrestartFormsService from "../Services/PrestartFormsService";
import QAFormsService from "../Services/QAFormsService";

/**
 * @typedef Session
 * @property {string} Name
 * @property {number} Role
 * @property {string} UserId
 * @property {string} BearerToken
 */

/** @type {SessionStore} */
var SessionStoreInstance = null;

const SessionStoreContext = createContext(null);

export default class SessionStore {
	constructor(config) {
		/** @type { Session } */
		this.Session = this.LoadPreviousSession();
		this.Config = config;

		//services
		this.UsersService = new UsersService(config.apiUrl, this);
		this.SalesQuotesService = new SalesQuotesService(config.apiUrl, this);
		this.JobSheetsService = new JobSheetsService(config.apiUrl, this);
		this.AccidentsAndIncidentsService = new AccidentsAndIncidentsService(config.apiUrl, this);
		this.VehicleInspectionsService = new VehicleInspectionsService(config.apiUrl, this);
		this.RemediationReportsService = new RemediationReportsService(config.apiUrl, this);
		this.PrestartFormsService = new PrestartFormsService(config.apiUrl, this);
		this.QAFormsService = new QAFormsService(config.apiUrl, this);
		
		makeAutoObservable(this);
	}

	LoadPreviousSession() {
		var sessionData = localStorage.getItem("Session");

		if(sessionData) {
			return JSON.parse(sessionData);
		}

		return null;
	}

	SetSession(session) {
		this.Session = session;
		
		localStorage.setItem("Session", JSON.stringify(session));
	}

	Logout() {		
		localStorage.clear();
		sessionStorage.clear();

		this.Session = null;
	}
}

export function SessionStoreProvider(props) {
    if(SessionStoreInstance === null) {
        SessionStoreInstance = new SessionStore(props.config);
    }

    return <SessionStoreContext.Provider value={ SessionStoreInstance }>{ props.children }</SessionStoreContext.Provider>
}

/**
 * @returns {SessionStore}
 */
export function useSessionStore() {
    const context = useContext(SessionStoreContext);

    return context;
}