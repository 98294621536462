import Modal from 'react-bootstrap/Modal';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Actions from "../../../Common/Components/Actions";
import ISODate from "../../../../Core/ISODate";

export default function JobSheetActionsModal({show, setShow, forms, jobSheetId}) {
	const navigate = useNavigate();

	const onClick = (formType) => {
		switch(formType) {
			case "PR":
				navigate("/JobSheets/Prestart/Add", {state: { jobSheetId }});
				break;
			case "QA":
				navigate("/JobSheets/QualityAssurance/Add", {state:  { jobSheetId }});
				break;
			case "VR":
				navigate("/JobSheets/VariationAndRemediation/Add", {state:  { jobSheetId }});
				break;
		}
	}

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="xl"
            scrollable
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header className="px-0">
                <Row className="d-flex justify-content-between w-100 m-0">
					<Col className="col-12 col-md-4 mb-3 mb-md-3">
						<Button className="w-100" variant="secondary" onClick={() => onClick("PR")}>Add Pre-Start Form</Button>
					</Col>
					<Col className="col-12 col-md-4 mb-3 mb-md-3">
						<Button className=" w-100" variant="secondary" onClick={() => onClick("QA")}>Add QA Form</Button>
					</Col>
					<Col className="col-12 col-md-4">
						<Button className="w-100" variant="secondary" onClick={() => onClick("VR")}>Add V&R Form</Button>
					</Col>
				</Row>
            </Modal.Header>
            <Modal.Body>                
                <Row className="h-25">
                    <Table>
							<thead>
								<tr>
									<th>Job Sheet Number</th>
									<th>Form Number</th>
									<th className="d-none d-md-table-cell">Date</th>
									<th className="text-center">Actions</th>
								</tr>
							</thead>
							<tbody>
								{
									forms && forms.map(form => {
										let formNumber = null;
										const jobNumber = `JS-EST${form.JobSheetNumber}`;
										let route = "";

										if (form.hasOwnProperty("RemediationReportNumber")) {

											formNumber = `${jobNumber}-VR${form.RemediationReportNumber}`;
											route = `/JobSheets/VariationAndRemediation/${form.Id}/${jobNumber}`;
										} else if (form.hasOwnProperty("QAFormNumber")) {

											formNumber = `${jobNumber}-QA${form.QAFormNumber}`;
											route = `/JobSheets/QualityAssurance/${form.Id}/${jobNumber}`;
										} else if (form.hasOwnProperty("PrestartFormNumber")) {

											formNumber = `${jobNumber}-PR${form.PrestartFormNumber}`;
											route = `/JobSheets/Prestart/${form.Id}/${jobNumber}`;
										}

										return (
											<tr key={form.Id}>
												<td>{ jobNumber }</td>
												<td>{ formNumber }</td>
												<td className="d-none d-md-table-cell"><ISODate date={form.Date}/></td>
												<td align="center">
													<Actions
														edit={{ action: () => navigate(route, {state: { action: "edit" }}), title: "Edit" }}
														view={{ action: () => navigate(route, {state: { action: "view" }}), title: "View" }}
													/>
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</Table>
                </Row>
            </Modal.Body>
            <Modal.Footer>                
                <Col className="col-2 offset-9">
                    <Button className="w-100" variant="secondary" onClick={() => setShow(false)}>Cancel</Button>
                </Col>
            </Modal.Footer>
        </Modal>
    )
}
