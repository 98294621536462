import { Col, Row, Stack } from 'react-bootstrap';
import FormSection from "../../../Core/Forms/FormSection";
import InputField from "../../../Core/Forms/InputField";

const CustomerDetails = ({ disabled }) => {

    return (
        <FormSection title="Customer Details">
            <Stack gap={3}>
                <Row>
                    <Col className="col-12 col-xl-6">
                        <InputField label="Customer" name="Customer.Name" disabled={disabled} required={!disabled}/>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12 col-xl-6 mb-3 mb-xl-0">
                        <Stack gap={3}>
                            <InputField label="Primary Contact Person First Name" name="Customer.PrimaryContactFirstName" disabled={disabled} required={!disabled}/>
                            <InputField label="Phone Number" name="Customer.PhoneNumber" disabled={disabled} required={!disabled}/>
                        </Stack>
                    </Col>
                    <Col className="col-12 col-xl-6">
                        <Stack gap={3}>
                            <InputField label="Primary Contact Person Last Name" name="Customer.PrimaryContactLastName" disabled={disabled} required={!disabled}/>
                            <InputField label="Email" name="Customer.EmailAddress" disabled={disabled} required={!disabled}/>
                        </Stack>
                    </Col>
                </Row>
                <Row>
                    <p className="fw-bold">Billing Address</p>
                </Row>
            </Stack>
            <Stack gap={3}>
                <Row>
                    <Col className="col-12 col-xl-6">
                        <Stack gap={3}>
                            <InputField label="Address Line 1" name="Customer.AddressLine1" disabled={disabled} required={!disabled}/>
                            <InputField className="mb-3 mb-xl-0" label="City" name="Customer.City" disabled={disabled} required={!disabled}/>
                        </Stack>
                    </Col>
                    <Col className="col-12 col-xl-6">
                        <InputField label="Address Line 2" name="Customer.AddressLine2" disabled={disabled} required={!disabled}/>
                    </Col>
                </Row>
            </Stack>
        </FormSection>
    )
}

export default CustomerDetails;