import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { DateTime } from "luxon";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../../../Core/PageContainer";
import Panel from "../../../../Core/Panel";
import Headerbar from "./Headerbar";
import Actions from "../../../Common/Components/Actions";

export default function AccidentsIncidents() {
    const navigate = useNavigate();
    const store = useSessionStore();

    const [incidents, setIncidents] = useState(null);

    useEffect(() => {
        getIncidents("");        
    }, [])

    async function getIncidents(search) {

        setIncidents(null);

        let result = await store.AccidentsAndIncidentsService.GetIncidents(search);

        if (result && result.Success) {
            
            result.Data.sort((a, b) => b.AINumber - a.AINumber);

            setIncidents(result.Data);
        }
    }

    return <PageContainer title="Health And Safety" subtitle="Accidents & Incidents">
        <Panel>
            <Headerbar onSearchClicked={(search) => getIncidents(search)}></Headerbar>
            <hr />
            <Row>
                <Col>
                    <Table>
                        <thead>
                            <tr>
                                <th>AI Number</th>
                                <th className="d-none d-lg-table-cell">Date of Incident</th>
                                <th className="d-none d-lg-table-cell">Organisation</th>
                                <th>Person Injured</th>
                                <th className="d-none d-md-table-cell">Location</th>
                                <th className="d-none d-lg-table-cell">Type of Injury</th>
                                <th className="w-25 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                incidents &&
                                incidents.map((incident, index) =>
                                    <tr key={`Incident-${index}`}>
                                        <td>{`AI${incident.AINumber}`}</td>
                                        <td className="d-none d-lg-table-cell">{DateTime.fromISO(incident.DateOfIncident).toFormat("dd/MM/yyyy")}</td>
                                        <td className="d-none d-lg-table-cell">{incident.Organisation}</td>
                                        <td>{incident.PersonInjured}</td>
                                        <td className="d-none d-md-table-cell">{incident.Location}</td>
                                        <td className="d-none d-lg-table-cell">{incident.Injuries.join(", ")}</td>
                                        <td className="align-center">
                                            <Actions
                                                edit={{ action: () => navigate(`/AccidentsIncidents/${incident.Id}`, {state: { action: "edit" }}), title: "Edit Accidents and Incident" }}
                                                view={{ action: () => navigate(`/AccidentsIncidents/${incident.Id}`, {state: { action: "view" }}), title: "View Accidents and Incident" }}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>

        </Panel>
    </PageContainer>
}