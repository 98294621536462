import { Stack, Row, Col, Form } from 'react-bootstrap';
import FormSection from "../../../../Core/Forms/FormSection";
import InputField from "../../../../Core/Forms/InputField";
import { getBodyPart, getInjuryType, getBodyPartsLength, getInjuryTypesLength } from "../../../../../Utilities/Enums";

const InjuryDetails = ({ disabled, bodyParts, injuryTypes, setBodyPartsInjuryType }) => {

    const CheckBox = ({label, index, checkboxes, bodyPartInjuryType}) => {
        return (
            <Form.Check>
                <Form.Check.Input
                    type={"checkbox"}
                    disabled={disabled}
                    checked={ checkboxes?.indexOf(index) > -1 }
                    onChange={ e => setBodyPartsInjuryType(index, e.target.checked, bodyPartInjuryType) }
                />
                <Form.Check.Label>{label}</Form.Check.Label>
            </Form.Check>
        )
    }

    const getCheckBoxes = (getLabel, labelsLength, boxes, bodyPartInjuryType, cols) => {
        
        let checkboxes = [];
        
        for (let i = 1; i < labelsLength() + 1; i++) {
            checkboxes.push(<Col key={getLabel(i)} className={`${cols === 3 ? "col-6 col-xl-4" : "col-6"} ${cols === 3 && i % 2 === 0 ? "mb-3 mb-xl-0" : ""}`}><CheckBox label={getLabel(i)} index={i} checkboxes={boxes} bodyPartInjuryType={bodyPartInjuryType}/></Col>);
        }

        let rows = [];
        
        for (let i = 0; i < checkboxes.length; i += cols) {

            rows.push(                
                <Row key={`Checkbox-Row-${i}`}>
                    {checkboxes.slice(i, i + cols)}
                </Row>
            );
        }

        return rows;
    }

    return (
        <FormSection title="Injury Details">
            <Stack>
                <Row>
                    <Col className="col-12 col-xl-6 border-end border-black mb-3 mb-xl-0">
                        <Stack gap={3}>
                            <Row>
                                <p className="fw-bold fs-5">Which part of the body was affected?</p>
                            </Row>
                            { getCheckBoxes(getBodyPart, getBodyPartsLength, bodyParts, "InjuredBodyParts", 3) }
                        </Stack>
                        <Col className="pt-0 pt-xl-3">
                            <InputField label="Other" name="InjuredBodyPartOther" type="textarea" disabled={disabled}/>
                        </Col>
                    </Col>
                    <Col className="col-12 col-xl-6">
                        <Stack gap={3}>
                            <Row>
                                <p className="fw-bold fs-5">Type Of Injury?</p>
                            </Row>
                            { getCheckBoxes(getInjuryType, getInjuryTypesLength, injuryTypes, "InjuryTypes", 2) }
                        </Stack>
                        <Col className="pt-3">
                            <InputField label="Other" name="InjuryTypeOther" type="textarea" disabled={disabled}/>
                        </Col>
                    </Col>                     
                </Row>
                <Row className="pt-3">
                    <InputField label="Comments" name="InjuryComments" type="textarea" disabled={disabled}/>
                </Row>
            </Stack>
        </FormSection>
    )
}

export default InjuryDetails;