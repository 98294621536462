import { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { Roles } from "../../../../../Utilities/Enums";
import QualityAssuranceValidationSchema from "../Validation/QualityAssuranceValidationSchema";
import QA from "./QA";
import PageContainer from "../../../../Core/PageContainer";
import FormContainer from "../../../../Core/Forms/FormContainer";
import FormSection from "../../../../Core/Forms/FormSection";
import CompletedBySection from "../../../../Core/Forms/CompletedBySection";
import Details from "./Details";
import AssignToTeamLeader from "../../../Common/Components/AssignToTeamLeader";
import QADetails from "../../../Common/Components/QADetails";
import MessageModal from "../../../../Core/Forms/MessageModal";

export default function QualityAssuranceForm() {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
	const store = useSessionStore();
    const createQAForm = location?.pathname === "/JobSheets/QualityAssurance/Add" ? true : false;
    const adminDisabled = store.Session.Role === Roles.Admin ? false : true;

    const form = useForm({mode: "onChange", resolver: yupResolver(QualityAssuranceValidationSchema)});
    const { register, handleSubmit, formState, setValue } = form;

    const [qaForm, setQAForm] = useState(null);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [viewOnly, setViewOnly] = useState(location.state?.action && location.state.action === "view" ? true : false);
    const [modal, setModal] = useState({show: false, header: "", message: ""});

    useEffect(() => {
        if (params.id) {

            getQAForm(params.id);

        } else if (location.state?.jobSheetId) {

            (async () => {
                
                let jobSheet = await getJobSheet(location.state.jobSheetId);
                
                setQAForm({
                    ...QA,
                    Site: jobSheet.Site,
                    SalesQuoteId: jobSheet.SalesQuoteId,
                    JobSheetNumber: `JS-EST${jobSheet.SalesQuoteNumber}`,
                    JobSheetId: location.state.jobSheetId
                });

            })();
        }
	}, []);

    useEffect(() => {
        if (qaForm) form.reset(qaForm, {keepDirtyValues: true});

    }, [qaForm]);

    useEffect(() => {

        if (Object.keys(formState.errors).length === 0) {
            setSubmitEnabled(true);
        } else {
            setSubmitEnabled(false);
        }
    }, [formState]);

    const getQAForm = async (qaFormId) => {
            
        let result = await store.QAFormsService.GetQAForm(qaFormId);

        if (result && result.Data) {
                        
            if (result.Data.QAForm.SubmittedToAdminOn && store.Session.Role !== Roles.Admin) {
                
                setViewOnly(true);
            }

            setQAForm({
                ...result.Data.QAForm,
                Site: result.Data.Site,
                JobSheetNumber: params.jobNumber ? params.jobNumber : null

            })
        }
    }

    const getJobSheet = async (jobSheetId) => {

        let result = await store.JobSheetsService.GetJobSheet(jobSheetId);

        if (result && result.Data) { 

            return {
                SalesQuoteId: result.Data.SalesQuote.salesQuoteId,
                SalesQuoteNumber: result.Data.SalesQuote.SalesQuoteNumber,
                TeamLeaderId: result.Data.SalesQuote.TeamLeaderId,
                Site: result.Data.SalesQuote.Site                
            }
        }
    }

    const onSave = async (submit = false) => {
            
        let data = form.getValues();

        if (data.Date === null) data.Date = "0001-01-01";

        delete data.Site;        
        delete data.JobSheetNumber;
        data.QAFormNumber = null;

        for (let i = 0; i < data.Photos.length; i++) {
            if (data.Photos[i].Base64Data === "") {
                data.Photos.splice(i, 1);
                i--;
            }
        }

        let result = null;

        if (createQAForm) {
            
            result = await store.QAFormsService.CreateQAForm(data);

        } else {            

            result = await store.QAFormsService.UpdateQAForm(data);
        }

        if (!submit) {
            if (result?.Success) {

                setModal({
                    show: true,
                    header: "Success!",
                    message: "QA Form saved successfully",
                    onClose: () => {onSuccess()}
                });
            } else {
                setModal({
                    show: true,
                    header: "Error!",
                    message: "An error occurred while saving the QA Form",
                    onClose: () => {setModal({show: false, header: "", message: ""})}
                });
            }
        }

        return result?.Data;
    }

    const onSubmit = async () => {

        let qaFormId = params.id ? params.id : null;

        if (!params.id) {
            qaFormId = await onSave(true);
        } else {
            await onSave(true);
        }

        if (!qaFormId) return;

        let result = await store.QAFormsService.SubmitQAForm(qaFormId);

        if (result?.Success) {
            setModal({
                show: true,
                header: "Success!",
                message: "QA Form submitted successfully",
                onClose: () => {onSuccess()}
            });
        } else {
            setModal({
                show: true,
                header: "Error!",
                message: result.Message,
                onClose: () => {setModal({show: false, header: "", message: ""})}
            });
        }
    }

    const onError = (errors, e) => console.log(errors, e);

    const addFile = (event, index = -1) => {

        let files = event.target.files;

        if (files.length > 0) {

            let file = files[0];

            if (index >= 0) {

                setValue(`Photos.${index}.FileName`, file.name);
                setValue(`Photos.${index}.MimeType`, file.type);
            } else {
                setValue("PS3FormName", file.name);
                setValue("PS3FormMimeType", file.type);
            }

            if (file.size <= 2 * 1024 * 1024) {

                let reader = new FileReader();
    
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    
                    if (index >= 0) {

                        register(`Photos.${index}.Base64Data`);
                        setValue(`Photos.${index}.Base64Data`, e.target.result);
                        form.trigger(`Photos.${index}.Base64Data`);
                    } else {

                        setValue("PS3FormBase64", e.target.result);
                    }
                }
            } else {
                
                if (index >= 0 ) {

                    setValue(`Photos.${index}.Base64Data`, null);
                    form.trigger(`Photos.${index}.Base64Data`);
                }
            }
        }
    }

    const onSuccess = () => {
        setModal({show: false, header: "", message: ""});
        navigate(-1);
    }

	return 	<PageContainer title="Projects" subtitle="Job Sheets - Quality Assurance Form">
				{qaForm ?
                    <FormContainer
                        formTitle="Quality Assurance Form"
                        formNumber={qaForm && qaForm.QAFormNumber && qaForm.JobSheetNumber && `${qaForm.JobSheetNumber}-QA${qaForm.QAFormNumber}`}
                        viewOnly={viewOnly}
                    >
                        <FormProvider { ...form }>
                            <Form className="text-black" onSubmit={handleSubmit(onSubmit, onError)}>
                                <FormSection title="">
                                    <AssignToTeamLeader value={qaForm.TeamLeaderId}  date={qaForm && qaForm.Date} disabled={viewOnly || adminDisabled}/>
                                </FormSection>
                                <Details addFile={addFile} form={form} disabled={viewOnly}/>
                                <FormSection title="QA Details">
                                    <QADetails form={form} disabled={viewOnly} addFile={addFile}/>
                                </FormSection>
                                <CompletedBySection 
                                    SubmittedToAdminOn={qaForm?.SubmittedToAdminOn ? qaForm?.SubmittedToAdminOn : ""}
                                    disabled={viewOnly}
                                    onSave={onSave}
                                    submitEnabled={submitEnabled}                                    
                                />
                            </Form>
                        </FormProvider>
                    </FormContainer> :
                    <div className="text-center pt-5 mt-5">
                        <Spinner variant="light"></Spinner>
                    </div>
                }
                <MessageModal modal={modal}/>
			</PageContainer>
}