import { Form, Stack, Row, Col, Image } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";
import InputField from '../../../Core/Forms/InputField';
import PlusButton from '../../../Core/Forms/PlusButton';
import MinusButton from '../../../Core/Forms/MinusButton';

export default function QADetails({addFile, form, disabled}) {
    const { register, formState: { errors } } = useFormContext();

    const watchField = form.watch("Photos");

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: "Photos",
    });

    return (
        <Stack gap={3}>
            <InputField
                label="Date and items checked"
                name="DateAndItemsChecked"
                type="textarea"
                disabled={disabled}
            />
            <InputField
                label="Product & description of works"
                name="DescriptionOfWorks"
                type="textarea"
                disabled={disabled}
            />
            <InputField
                label="Results & readings"
                name="Results"
                type="textarea"
                disabled={disabled}
            />
            {
                fields.map((photo, index) => {
                    return (
                        <Stack key={`Photos.${index}`}>
                            <Col className="col-12 col-md-6">
                                {!disabled &&
                                    <Form.Group controlId="formFile" className="mb-3 d-print-none">
                                        <p className="">Photo (limit 2MB)</p>
                                        <Form.Control
                                            name={`Photos`}
                                            type="file"
                                            onChange={(event) => {addFile(event, index)}}
                                            disabled={disabled}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name={`Photos.${index}.Base64Data`}
                                            render={({ message }) => <Form.Text className="text-danger">{ message }</Form.Text>}
                                        />
                                    </Form.Group>
                                }
                                {watchField && form.getValues(`Photos.${index}.Base64Data`)?.length > 0 &&
                                    <Col className="col-12">
                                        <Image { ...register(`Photos.${index}.Base64Data`) } src={form.getValues(`Photos.${index}.Base64Data`)} rounded thumbnail/>
                                    </Col>
                                }
                            </Col>
                        </Stack>
                    )
                })
            }
            {!disabled && 
                <Row>
                    <Col className="col-6 d-flex justify-content-start">
                        <Stack direction="horizontal">
                            <PlusButton                                        
                                onClick={
                                    () => {
                                        append({
                                            Id: null,
                                            Base64Data: "",
                                            FileName: "",
                                            MimeType: "" 
                                        })
                                    }
                                }
                                disabled={disabled}
                            />
                            {
                                fields.length > 1 &&
                                <MinusButton onClick={() => {remove(-1)}} disabled={disabled}/>
                            }
                        </Stack>
                    </Col>
                </Row>
            }            
        </Stack>
    )
}
