const Quote = {
    Id: "",
    TeamLeaderId: null,
    SalesRepId: null,
    SubmittedToTeamLeaderOn: null,
    SubmittedToTeamLeaderById: null,
    SubmittedToAdminOn: null,
    EstimateAccepted: false,
    DepositReceived: false,
    Invoiced: false,
    JobCompleted: false,
    HoursTaken: "",
    LeadFrom: "",
    DateScheduled: "",
    Date: "",
    SalesQuoteSiteId: "",
    SpecialMachineryRequired: "",
    AdditionalInformation: "",
    CustomerBrief: "",
    SalesQuoteNumber: "",
    JobRequirements: [],
    Photos: [],
    JobDescriptions: [
        {
            Id: null,
            Task: "0",
            Squares: null,
            LinealMeters: null,
            UnitCost: "",
            AdditionalInformation: ""
        }
    ],
    JobProductDetails: [
        {
            Id: null,
            Supplier: "",
            Quantity: "",
            MixRatio: "",
            Coverage: "",
            Colour: ""
        }
    ],
    Customer: {
        Name: "",
        PrimaryContactFirstName: "",
        PrimaryContactLastName: "",
        PhoneNumber: "",
        EmailAddress: "",
        AddressLine1: "",
        AddressLine2: "",
        City: ""
    },
    Site: {
        ContactPersonFirstName: "",
        ContactPersonLastName: "",
        PhoneNumber: "",
        EmailAddress: "",
        AddressLine1: "",
        AddressLine2: ""
    },
    SalesQuoteSite: {
        Cutting: false,
        Flooring: false,
        ChaseCutting: false,
        DaysAllowed: "",
        Teams: "",
        TotalProductionCost: 0
    },
    CompletedBy: ""
}

export default Quote;