const Incident = {    
    InjuryComments: "",
    Branch: "",
    DateReported: null,
    DateOfIncident: null,
    TimeOfIncident: null,
    InjuredBodyPartOther: "",
    InjuryComments: "",
    InjuryTypeOther: "",
    NameOfOrganisation: "",
    Location: "",
    IncidentPersonDetails: {
        PhoneNumber: "",
        DateOfBirth: null,
        Address: "",
        LengthOfEmployment: "",
        Name: ""
    },
    IncidentInformation: {
        ActionsToPreventAccidentReoccurring: "",
        IncidentDescription: "",
        CauseOfAccident: "",
        AdditionalComments: "",
        CauseOfDamage: "",
        ChanceOfRepeat: "",
        NatureOfDamage: "",
        PropertyDamaged: "",
        Seriousness: "",
        StepsToMinimizeHazard: ""
    },
    InjuredBodyParts: [],
    InjuryTypes: [],
}

export default Incident;