import { Col, Row, Stack } from 'react-bootstrap';
import FormSection from '../../../../Core/Forms/FormSection';
import InputField from '../../../../Core/Forms/InputField';
import DateField from '../../../../Core/Forms/DateField';

const PersonsDetails = ({ dateOfBirth, disabled }) => {

  return (
    <FormSection title="Person's Details">
        <Stack gap={3}>
            <Row>
                <Col className="col-12 col-xxl-6 mb-3 mb-xxl-0">
                    <InputField label="Name" name="IncidentPersonDetails.Name" disabled={disabled} required={!disabled}/>
                </Col>
                <Col className="col-12 col-xxl-6">
                    <DateField label="Date of Birth" name="IncidentPersonDetails.DateOfBirth" value={dateOfBirth} disabled={disabled} required={!disabled}/>
                </Col>
            </Row>
            <Row>
                <Col className="col-12 col-xxl-6 mb-3 mb-xxl-0">
                    <InputField label="Phone Number" name="IncidentPersonDetails.PhoneNumber" disabled={disabled} required={!disabled}/>
                </Col>
                <Col className="col-12 col-xxl-6">
                    <InputField label="Length of Employment" name="IncidentPersonDetails.LengthOfEmployment" disabled={disabled} />
                </Col>
            </Row>
            <Row>
                <InputField label="Address" name="IncidentPersonDetails.Address" type="textarea" disabled={disabled} required={!disabled}/>
            </Row>
        </Stack>
    </FormSection>
  )
}

export default PersonsDetails;