import { useRef } from 'react';
import { Col, Row, Container, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import { ReactToPrint } from 'react-to-print';
import FormHeader from "./FormHeader";

export default function FormContainer({ children, formTitle, formNumber, viewOnly }) {

    const printElement = useRef(null);

    return  <Container fluid className="min-vh-100 px-0 bg-white col-md-9 col-sm-12 text-white bg-secondary rounded-top-1" ref={printElement}>                    
                <FormHeader formTitle={formTitle} formNumber={formNumber}/>
                <Row className="">
                    <Col className="text-black col-8 col-md-10">
                        <p className="pt-1 px-4 fw-bold fs-4">Concrete Specialists NZ 2022</p>
                    </Col>
                    {viewOnly &&
                        <Col className="col-4 col-md-2 mt-3">
                            <ReactToPrint
                                documentTitle={`${formTitle} - ${formNumber}`}
                                trigger={() => <Button variant="primary" className="d-print-none">Print<FontAwesomeIcon icon={faPrint} className="ps-3"></FontAwesomeIcon></Button>}
                                content={() => printElement.current}
                            />
                        </Col>
                    }
                </Row>

                { children }

            </Container>
}