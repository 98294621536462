export const Roles = {
	SalesRep: 1,
	TeamLeader: 2,
	Admin: 3
}

export const ProjectStatuses = {
	Current: 1,
	Completed: 2
}

export const JobRequirementLabels = {
	Power: 1,
	JointRepairs: 2,
	TASSPRequired: 3,
	ScaffoldingRequired: 4,
	SlurryControl: 5,
	HeightsLevels: 6,
	WaterOnSite: 7,
	ProtectionRequired: 8,
	Parking: 9,
	CrackRepairs: 10,
	Generator: 11,
	RubbishDisposal: 12,
	DrawingsApproved: 13,
	Inside: 14,
	Outside: 15,
	InsideOutside: 16,
	QARequired: 17,
	PS3Required: 18,
}

export const LeaderRequirements = {
	SetupAndRunJob: 1,
	BeOnTime: 2,
	CheckCorrectProduct: 3,
	FillInAllWork: 4,
	WorkInTradesmanlikeMAnner: 5,
	ReportToManagement: 6,
	RecordColoursEtc: 7,
	QARequired: 8,
	PS3Required: 9
}

export const TaskOptions = [
	{ 	value: "0", label: "Please Select",
		additionalInfo: 
			``
	},
	{ 	value: "15", label: "Acid Wash or Clean & Seal",
		additionalInfo: 
			`Scope of Work - LIGHT/MED/HEAVY  (M2/LM)
			
1. Clean area
2. Apply acid / or Clean area 
3. Let acid sit 10-15 mins, then wash away with water
4. Once dry apply 1-2 coats of sealer.`
	},
	{ 	value: "1", label: "Block Honing (Certified System)",
		additionalInfo: 
			`Scope of Work - Stratmore Sealing System  (M2)

1. Medium grind surface
2. Copper pad
3. Apply 2 x Coats of Aquellux SWB
4. Apply 3 x Coats of sealer`
	},
	{ 	value: "2", label: "Bush Hammering (Certified System) Light/Medium/Heavy",
		additionalInfo: 
			`Scope of Work - Light/Medium/Heavy  (M2)

1. Break surface 25/30
2. Medium/Heavy Bush hammer to required areas
3. Densify
4. Apply 1 x coat of solvent sealer
5. PS3 & Opus Statement for anti-slip created by bush hammering`
	},
	{ 	value: "16", label: "Bush Hammering (Non-Certified System) Light/Medium/Heavy",
		additionalInfo: 
			`Scope of Work – Light/Medium/Heavy  (M2)
			
1. Break surface 25/30
2. Medium/Heavy Bush hammer to required areas
3. Densify`
	},
	{ 	value: "17", label: "Crack Repairs",
		additionalInfo: 
			`Scope of work - Crack Repairs (M2/LM)
			
1. Clean out joints
2. Chase cracks and repair with Hi-Tech RX2 / Hi-Tech Spall TX3 / Polyurea 85 and bandage (if required)`
	},
	{ 	value: "3", label: "Epoxy Roll Coatings",
		additionalInfo: 
			`(Always use the supplies full system unless time is an issue (no product warranty, if we don’t follow the system)

Scope of Work (M2/LM)

1. Grind off existing coating to create key
2. Chamfer concrete edge if required
3. Chase out and clean joints/fill with flexible resin
4. Fill holes and imperfections on floor with Degadur mix
5. Apply [PRODUCT NAME] Primer coat
6. Apply first coat of [PRODUCT NAME] epoxy coating
7. Apply second coat of clear coating and anti-slip if required
8. Apply sealer coat if required

Note: Remove any if not applicable`
	},
	{ 	value: "4", label: "Floor Levelling (FLC)",
		additionalInfo: 
			`Scope of work

1. Prep area and create key
2. Grid and set heights
3. Prime 
4. Lay product
	
- Retail divided by .8
- Away day allowance/food etc @ $135.00`
	},
	{ 	value: "5", label: "Grind & Seal - Polyurethane(Med) x 2",
		additionalInfo: 
			`

			`
	},
	{ 	value: "6", label: "Grind & Seal - Solvent (Med) x 2",
		additionalInfo: 
			`Scope of Work - (Solvent-based Sealer or Water-based Sealer to a Matt Finish (M2/LM)

1. Grind to remove existing flooring sealer, contamination, or create stone exposure.
2. Apply 2 x coats [PRODUCT NAME] sealer

Note:
Two coats of Sealer or Guard are based on a tight porosity of concrete.  Extra costs may occur if additional coats are required.
Customer to check at first metal cut and sign acceptance.  If further metal cuts are required, $12.50 per cut will be added.`
	},
	{ 	value: "7", label: "Grinding Only",
		additionalInfo: 
			`Scope of Work - Grinding only (M2/LM)

1. Grid area if required
2. Grind as required (25/30 grit finish)`
	},
	{ 	value: "8", label: "Miscellaneous",
		additionalInfo: 
			`

			`
	},
	{ 	value: "9", label: "PnG / Setup / Health & Safety / Deliveries",
		additionalInfo: 
			`

			`
	},
	{ 	value: "10", label: "Polished Concrete",
		additionalInfo: 
			`Scope of Work -  PETER FELL C2 Polished Concrete System

1. Grind using a very coarse diamond to remove top surface and expose stone
2. Then several metal fine cuts to remove scratches
3. Apply polymer slurry to minimize air holes in slab (if required)
4. Apply PETER FELL C2 SYSTEM surface hardener treatment
5. Polish using a 4 step resin polishing process to a 800 grit resin bond finish
6. Apply PETER FELL C2 SYSTEM protective stain inhibitor and burnish
7. Final stage Burnish floor for sheen/matt finish

Note:  No allowance to clean and fill expansion cuts`
	},
	{ 	value: "11", label: "Polished Floors - 'Salt & Pepper' finish",
		additionalInfo: 
			`Scope of Work -  PETER FELL C2 Polished Concrete System (M2/LM)

1. Clean and fill expansion cuts (if required)
2. Grind using fine diamonds or resin pad to create a salt & pepper effect
3. Apply polymer slurry to minimize air holes in slab if required
4. Apply PETER FELL C2 SYSTEM surface hardener treatment
5. Polish using a 4 step resin polishing process to a 800 grit resin bond finish
6. Apply PETER FELL C2 SYSTEM protective stain inhibitor and burnish
7. Final stage Burnish floor for sheen/matt finish`
	},
	{ 	value: "12", label: "Tiling",
		additionalInfo: 
			`Scope of work - Tiling (M2/LM)

1. Prep area to create key
2. Establish falls
3. Waterproof (if required, and added to price)
4. Establish pattern
5. Apply tile adhesive
6. Install tiles - Customer to select and provide tiles 
7. Grout - Customer to select Mapei Grout colour

https://www.mapei.com/nz/en/tools-and-downloads/coloured-grouts-and-sealants`
	},
	{ 	value: "13", label: "Waterproofing - Krystoline T 1/T2 System",
		additionalInfo: 
			`Scope of Work - Krystoline T1 system (M2/LM)

1. Drill holes in the walls and plug with Krystoline grout
2. Apply T1 product to walls 
			
** Client to keep damp for a week **`
	},
	{ 	value: "14", label: "Waterproofing - Aquadefence",
		additionalInfo: 
			`Scope of work - Water proofing Aquadefence OR MR (M2/LM)

1. Create key for product
2. Mapei band floor to wall
3. Apply a minimum of 2 x Aquadefence coats
	
Note:  MR system works both on negative/positive pressure, but does have limitations 40 bar of head pressure.  It will not stop water at wall to floor or entry from walls, doorways etc.`
	} 
]

export const BodyParts = {
	Head: 1,
	Neck: 2,
	Eyes: 3,
	EarsHearing: 4,
	Shoulders: 5,
	BackSpine: 6,
	UpperArm: 7,
	LowerArm: 8,
	Wrist: 9,
	Hips: 10,
	Buttocks: 11,
	UpperLeg: 12,
	LowerLeg: 13,
	Ankle: 14,
	Foot: 15,
	Toes: 16,
	Knees: 17
}

export const InjuryTypes = {
	Sprain: 1,
	Strain: 2,
	Fracture: 3,
	Amputation: 4,
	CutBurn: 5,
	Bruising: 6,
	Dislocation: 7,
	Scratch: 8,
	Abrasion: 9,
	ForeignBody: 10,
	Laceration: 11
}
export const injuryTypesLabels = [
	"Sprain",
	"Strain",
	"Fracture",
	"Amputation",
	"Cut Burn",
	"Bruising",
	"Dislocation",
	"Scratch",
	"Abrasion",
	"Foreign Body",
	"Laceration"
]

export const VehicleParts = {
	BrakeOperation : 1,
	RearViewMirror : 2,
	SideMirrors : 3,
	HornOperation : 4,
	SeatBeltsOperation : 5,
	CleanInterior : 6,
	FirstAidKit : 7,
	MaterialSafety :8,
	Windscreen : 9,
	WipersAndWiperCondition : 10,
	OilLeaks : 11,
	FluidLevels : 12,
	TyresCondition : 13,
	Panels : 14,
	NoUnusualSounds : 15,
	WheelJackBrace : 16,
	RoofRacksSecure : 17,
	TailLights : 18,
	BrakeStopLights : 19,
	RearIndicatorLights : 20,
	ReversingLights : 21,
	Headlights : 22,
	ParkLights : 23,
	FrontIndicatorLights : 24,
	WheelChocks : 25,
	EarthLeads : 26,
	NightLights : 27
}

const Hazards = {
	SilicaDust: 0,
	Vibrations: 1,
	Electrical: 2,
	Noise: 3,
	Chemicals: 4,
	Fumes: 5,
	Obstructions: 6,
	OverheadObstructions: 7,
	WorkingAtHeight: 8,
	SpendingLongTime: 9,
	Trenches: 10,
	NotEnoughLight: 11,
	ConfinedSpace: 12,
	UVRays: 13,
	HotWeather: 14,
	UnevenSurfaces: 15
}

const Statuses = [
	"Estimate Authorised",
	"Deposit Received",
	"Invoiced",
	"Job Completed"
]

export function getRoleName(role) {
	switch(role) {
		case Roles.SalesRep:
			return "Sales Rep";
		case Roles.TeamLeader:
			return "Team Leader";
		case Roles.Admin:
			return "Admin";
		default:
			return "Unknown";
	}
}

export function getProjectStatusName(status) {
	switch(status) {
		case ProjectStatuses.Current:
			return "Current";
		case ProjectStatuses.Completed:
			return "Completed";
		default:
			return "Unknown";
	}
}

export const getLeaderRequirementsLabel = (requirement) => {
	switch (requirement) {
		case LeaderRequirements.SetupAndRunJob:
			return "Setup and run job as expected.";
		case LeaderRequirements.CheckCorrectProduct:
			return "Check the correct product and gear are on site the first day.";
		case LeaderRequirements.WorkInTradesmanlikeMAnner:
			return "Work in a tradesmanlike manner at all times.";
		case LeaderRequirements.RecordColoursEtc:
			return "Record colours, mix ratios etc daily.";
		case LeaderRequirements.PS3Required:
			return "PS3 Required.";
		case LeaderRequirements.BeOnTime:
			return "Be on time and don't leave site before 4pm without speaking to management.";
		case LeaderRequirements.FillInAllWork:
			return "Fill in all Health & Safety work and hand back to Health & Safety Officer.";
		case LeaderRequirements.ReportToManagement:
			return "Report back to management daily and take photos of work done on the day.";
		case LeaderRequirements.QARequired:
			return "QA required";
		default:
			return "";
	}
}

export function getBodyPart(bodyPart) {

	switch (bodyPart) {
		case BodyParts.Head: 
			return "Head";
		case BodyParts.Neck: 
			return "Neck";
		case BodyParts.Eyes: 
			return "Eyes";
		case BodyParts.EarsHearing: 
			return "Ears/Hearing";
		case BodyParts.Shoulders: 
			return "Shoulders";
		case BodyParts.BackSpine: 
			return "Back/Spine";
		case BodyParts.UpperArm: 
			return "Upper Arm";
		case BodyParts.LowerArm: 
			return "Lower Arm";
		case BodyParts.Wrist: 
			return "Wrist";
		case BodyParts.Hips: 
			return "Hips";
		case BodyParts.Buttocks: 
			return "Buttocks";
		case BodyParts.UpperLeg: 
			return "Upper Leg";
		case BodyParts.LowerLeg: 
			return "Lower Leg";
		case BodyParts.Ankle: 
			return "Ankle";
		case BodyParts.Foot: 
			return "Foot";
		case BodyParts.Toes: 
			return "Toes";
		case BodyParts.Knees: 
			return "Knees";
	}
}

export function getBodyPartsLength() {
	return Object.keys(BodyParts).length;
}

export function getInjuryType(injuryType) {

	switch (injuryType) {
		case InjuryTypes.Sprain:
			return "Sprain";
		case InjuryTypes.Strain:
			return "Strain";
		case InjuryTypes.Fracture:
			return "Fracture";
		case InjuryTypes.Amputation:
			return "Amputation";
		case InjuryTypes.CutBurn:
			return "Cut Burn";
		case InjuryTypes.Bruising:
			return "Bruising";
		case InjuryTypes.Dislocation:
			return "Dislocation";
		case InjuryTypes.Scratch:
			return "Scratch";
		case InjuryTypes.Abrasion:
			return "Abrasion";
		case InjuryTypes.ForeignBody:
			return "Foreign Body";
		case InjuryTypes.Laceration:
			return "Laceration";
	}
}

export function getInjuryTypesLength() {
	return Object.keys(InjuryTypes).length;
}

export function getJobRequirement(requirement) {

	switch (requirement) {
		case JobRequirementLabels.Power:
			return "Power";
		case JobRequirementLabels.JointRepairs:
			return "Joint Repairs";
		case JobRequirementLabels.TASSPRequired:
			return "TA & SSP Required";
		case JobRequirementLabels.ScaffoldingRequired:
			return "Scaffolding Required";
		case JobRequirementLabels.SlurryControl:
			return "Slurry Control";
		case JobRequirementLabels.HeightsLevels:
			return "Heights / Levels";
		case JobRequirementLabels.WaterOnSite:
			return "Water on Site";
		case JobRequirementLabels.ProtectionRequired:
			return "Protection Required";
		case JobRequirementLabels.Parking:
			return "Parking";
		case JobRequirementLabels.CrackRepairs:
			return "Crack Repairs";
		case JobRequirementLabels.Generator:
			return "Generator";
		case JobRequirementLabels.RubbishDisposal:
			return "Rubbish Disposal";
		case JobRequirementLabels.DrawingsApproved:
			return "Drawings Approved";
		case JobRequirementLabels.Inside:
			return "Inside";
		case JobRequirementLabels.Outside:
			return "Outside";
		case JobRequirementLabels.InsideOutside:
			return "Inside & Outside";
		case JobRequirementLabels.QARequired:
			return "QA Required";
		case JobRequirementLabels.PS3Required:
			return "PS3 Required";
	}
}

export function getVehiclePartLabel(index) {
	return VehicleParts[index];
}

export function getVehiclePart(Id) {
	switch (Id) {
		case VehicleParts.BrakeOperation:
			return "Brake Operation - Foot and Hand Brake";
		case VehicleParts.RearViewMirror:
			return "Rear View Mirror";
		case VehicleParts.SideMirrors:
			return "Side Mirrors";
		case VehicleParts.HornOperation:
			return "Horn Operation";
		case VehicleParts.SeatBeltsOperation:
			return "Seat Belts Operation & Condition";
		case VehicleParts.CleanInterior:
			return "Clean Interior - free from loose objects";
		case VehicleParts.FirstAidKit:
			return "First Aid Kit & Fire Extinguisher - check test dates";
		case VehicleParts.MaterialSafety:
			return "Material Safety Data Sheets (MD SD Sheets)";
		case VehicleParts.Windscreen:
			return "Windscreen - free from cracks";
		case VehicleParts.WipersAndWiperCondition:
			return "Wipers and Wiper Condition";
		case VehicleParts.OilLeaks:
			return "Oil Leaks";
		case VehicleParts.FluidLevels:
			return "Fluid Levels";
		case VehicleParts.TyresCondition:
			return "Tyres Condition";
		case VehicleParts.Panels:
			return "Panels - report damage";
		case VehicleParts.NoUnusualSounds:
			return "No unusual sounds or smells (eg: muffler holes or burning)";
		case VehicleParts.WheelJackBrace:
			return "Wheel Jack/Brace";
		case VehicleParts.RoofRacksSecure:
			return "Roof Racks Secure";
		case VehicleParts.TailLights:
			return "Tail Lights";
		case VehicleParts.BrakeStopLights:
			return "Brake/Stop Lights";
		case VehicleParts.RearIndicatorLights:
			return "Indicator Lights";
		case VehicleParts.ReversingLights:
			return "Reversing Lights";
		case VehicleParts.Headlights:
			return "Headlights - High and low beams";
		case VehicleParts.ParkLights:
			return "Park Lights";
		case VehicleParts.FrontIndicatorLights:
			return "Indicator Lights";
		case VehicleParts.WheelChocks:
			return "Wheel Chocks";
		case VehicleParts.EarthLeads:
			return "Earth Leads";
		case VehicleParts.NightLights:
			return "Night Lights";
	}
}

export function getHazard(hazard) {

	switch (hazard) {
		case Hazards.SilicaDust: 
			return "Silica Dust";
		case Hazards.Vibrations: 
			return "Vibrations";
		case Hazards.Electrical: 
			return "Electrical - wires in the way / other electrical equipment in work area";
		case Hazards.Noise: 
			return "Noise";
		case Hazards.Chemicals: 
			return "Chemicals";
		case Hazards.Fumes: 
			return "Fumes";
		case Hazards.Obstructions: 
			return "Obstructions in work area";
		case Hazards.OverheadObstructions: 
			return "Overhead obstructions";
		case Hazards.WorkingAtHeight: 
			return "Working at height";
		case Hazards.SpendingLongTime: 
			return "Spending long time in same position / repetitive movements";
		case Hazards.Trenches: 
			return "Trenches";
		case Hazards.NotEnoughLight: 
			return "Not Enough Light";
		case Hazards.ConfinedSpace: 
			return "Confined space / bad airflow area";
		case Hazards.UVRays: 
			return "UV rays / sun exposure";
		case Hazards.HotWeather: 
			return "Hot Weather";
		case Hazards.UnevenSurfaces: 
			return "Uneven surfaces: gravel/slopes";
	}
}

export function getHazards() {
	return Hazards;
}

export function getHazardsLength() {
	return Object.keys(Hazards).length;
}

export function getTaskOptionsInformation(value) {

	value += "";

	if (!value) return "";
	
	return TaskOptions.find(option => option.value === value).additionalInfo;
}

export function getStatus(index) {
	return Statuses[index];
}