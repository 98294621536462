import { Col, Row, Table, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { getProjectStatusName } from "../../../../../Utilities/Enums";
import PageContainer from "../../../../Core/PageContainer";
import Headerbar from "./Headerbar";
import Panel from "../../../../Core/Panel";
import ISODate from "../../../../Core/ISODate";
import Actions from "../../../Common/Components/Actions";
import JobSheetActionsModal from "../Modal/JobSheetActionsModal";

export default function JobSheets() {	
	const navigate = useNavigate();
	const store = useSessionStore();

	const [jobSheets, setJobSheets] = useState(null);
	const [jobSheetForms, setJobSheetForms] = useState(null);
	const [jobSheetId, setJobSheetId] = useState("");
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
	
		getJobSheets("", "");
	}, []);

	async function getJobSheets(search, status) {

		setJobSheets(null);

		let result = await store.JobSheetsService.GetJobSheets(search, status);

		if(result && result.Data) {
			
			result.Data.sort((a, b) => b.SQNumber - a.SQNumber);

			setJobSheets(result.Data);
		}
	}

	async function getJobSheetsByTeamLeader(teamLeaderId) {
		if (!teamLeaderId) {
			getJobSheets("", "");
			return;
		}

		setJobSheets(null);

		let result = await store.JobSheetsService.GetJobSheetsByTeamLeader(teamLeaderId);
		
		if(result && result.Data) {

			result.Data.sort((a, b) => b.SQNumber - a.SQNumber);
			
			setJobSheets(result.Data);
		}
	}

	const getJobSheetForms = async (jobSheetId) => {
		let forms = null;
		let result = await store.RemediationReportsService.GetRemediationReports(jobSheetId);

		if (result && result.Data) {

			result.Data.sort((a, b) => b.RemediationReportNumber - a.RemediationReportNumber);

			forms = result.Data;
		}

		result = await store.PrestartFormsService.GetPrestartForms(jobSheetId);

		if (result && result.Data) {
			
			result.Data.sort((a, b) => b.PrestartFormNumber - a.PrestartFormNumber);

			if (forms) {
				forms = forms.concat(result.Data);
			} else {
				forms = result.Data;
			}
		}

		result = await store.QAFormsService.GetQAForms(jobSheetId);

		if (result && result.Data) {

			result.Data.sort((a, b) => b.QAFormNumber - a.QAFormNumber);

			if (forms) {
				forms = forms.concat(result.Data);
			} else {
				forms = result.Data;
			}
		}

		setJobSheetForms(forms);
	}

	const moreActions = (id) => {
		getJobSheetForms(id);
		setJobSheetId(id);
		setShowModal(true);
	};

	return 	<PageContainer title="Projects" subtitle="Job Sheets">
				<Panel>
					<Headerbar onFilterClicked={ (search, status) => getJobSheets(search, status) } onTeamLeaderSelected={ (teamLeaderId) => getJobSheetsByTeamLeader(teamLeaderId) }></Headerbar>
					<hr/>
					<Row>
						<Col>
							<Table>
								<thead>
									<tr>
										<th>Job Number</th>
										<th>EST Number</th>
										<th className="d-none d-lg-table-cell">Date</th>										
										<th className="d-none d-md-table-cell">Team Leader</th>
										<th className="d-none d-lg-table-cell">Sales Rep</th>
										<th className="d-none d-lg-table-cell">Referral</th>
										<th className="d-none d-md-table-cell">Client Name</th>
										<th className="text-center">Actions</th>
									</tr>
								</thead>
								<tbody>
									{
										jobSheets && jobSheets.map(js => 
										<tr key={js.Id}>
											<td>{ `JS-EST${js.SQNumber}` }</td>
											<td>{ `EST${js.SQNumber}` }</td>
											<td className="d-none d-lg-table-cell"><ISODate date={js.Date}/></td>
											<td className="d-none d-md-table-cell">{ js.TeamLeader }</td>
											<td className="d-none d-lg-table-cell">{ js.SalesRep }</td>
											<td className="d-none d-lg-table-cell">{ js.Referral }</td>
											<td className="d-none d-md-table-cell">{ js.ClientName }</td>
											<td align="center">
												<Actions
													edit={{ action: () => navigate(`/JobSheets/${js.Id}`, {state: { action: "edit" }}), title: "Edit Job Sheet" }}
													view={{ action: () => navigate(`/JobSheets/${js.Id}`, {state: { action: "view" }}), title: "View Job Sheet" }}
													moreActions={{ action: () => moreActions(js.Id), title: "More Actions" }}
												/>
											</td>
										</tr>)
									}
								</tbody>
							</Table>
							{
								!jobSheets &&
								<div className="text-center">
									<Spinner></Spinner>
								</div>
							}					
						</Col>
					</Row>
				</Panel>
				<JobSheetActionsModal show={showModal} setShow={setShowModal} forms={jobSheetForms} jobSheetId={jobSheetId}/>
			</PageContainer>
}