import { VehicleParts } from "../../../../../Utilities/Enums.js";

const Inspection = {
	AdditionalComments: "",
	RegistrationNumber: "",
	COFExpiryDate: null,
	RegistrationExpiryDate: null,
	HuboReadingExpiry: null,
	HuboReadingCurrent: "",
	OdometerReading: "",
	EmployeeName: "",
	NextServiceDue: null,
	DateCompleted: null,
	Items: [
        {Result: 3, VehiclePartId: VehicleParts.BrakeOperation},
        {Result: 3, VehiclePartId: VehicleParts.RearViewMirror},
        {Result: 3, VehiclePartId: VehicleParts.SideMirrors},
        {Result: 3, VehiclePartId: VehicleParts.HornOperation},
        {Result: 3, VehiclePartId: VehicleParts.SeatBeltsOperation},
        {Result: 3, VehiclePartId: VehicleParts.CleanInterior},
        {Result: 3, VehiclePartId: VehicleParts.FirstAidKit},
        {Result: 3, VehiclePartId: VehicleParts.MaterialSafety},
        {Result: 3, VehiclePartId: VehicleParts.Windscreen},
        {Result: 3, VehiclePartId: VehicleParts.WipersAndWiperCondition},
        {Result: 3, VehiclePartId: VehicleParts.OilLeaks},
        {Result: 3, VehiclePartId: VehicleParts.FluidLevels},
        {Result: 3, VehiclePartId: VehicleParts.TyresCondition},
        {Result: 3, VehiclePartId: VehicleParts.Panels},
        {Result: 3, VehiclePartId: VehicleParts.NoUnusualSounds},
        {Result: 3, VehiclePartId: VehicleParts.WheelJackBrace},
        {Result: 3, VehiclePartId: VehicleParts.RoofRacksSecure},
        {Result: 3, VehiclePartId: VehicleParts.TailLights},
        {Result: 3, VehiclePartId: VehicleParts.BrakeStopLights},
        {Result: 3, VehiclePartId: VehicleParts.RearIndicatorLights},
        {Result: 3, VehiclePartId: VehicleParts.ReversingLights},
        {Result: 3, VehiclePartId: VehicleParts.Headlights},
        {Result: 3, VehiclePartId: VehicleParts.ParkLights},
        {Result: 3, VehiclePartId: VehicleParts.FrontIndicatorLights},
        {Result: 3, VehiclePartId: VehicleParts.WheelChocks},
        {Result: 3, VehiclePartId: VehicleParts.EarthLeads},
        {Result: 3, VehiclePartId: VehicleParts.NightLights}
    ]
};

export default Inspection;