import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../../../Core/PageContainer";
import Panel from "../../../../Core/Panel";
import ISODate from "../../../../Core/ISODate";
import Headerbar from "./Headerbar";
import Actions from "../../../Common/Components/Actions";

export default function VehicleInspections() {
    const navigate = useNavigate();
    const store = useSessionStore();

    const [vehicleInspections, setVehicleInspections] = useState(null);

    useEffect(() => {
        getVehicleInspections("");
        
    }, [])

    async function getVehicleInspections(search) {
        setVehicleInspections(null);

        let result = await store.VehicleInspectionsService.GetVehicleInspections(search);

        if (result && result.Success) {

            result.Data.sort((a, b) => b.VINumber - a.VINumber);

            setVehicleInspections(result.Data);
        }

    }

    return <PageContainer title="Health and Safety" subtitle="Vehicle Inspection">
        <Panel>
            <Headerbar onSearchClicked={(search) => getVehicleInspections(search)}></Headerbar>
            <hr />
            <Row>
                <Col>
                    <Table>
                        <thead>
                            <tr>
                                <th>VI Number</th>
                                <th className="d-none d-lg-table-cell">Date Completed</th>
                                <th>Registration Number</th>
                                <th className="d-none d-md-table-cell">Driver's Name</th>
                                <th className="d-none d-lg-table-cell">Next Service Due</th>
                                <th className="w-25 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                vehicleInspections &&
                                vehicleInspections.map((vehicleInspection, index) =>
                                    <tr key={`Inspection-${index}`}>
                                        <td>{ `VI-${vehicleInspection.VINumber}` }</td>
                                        <td className="d-none d-lg-table-cell"><ISODate date={vehicleInspection.DateCompleted}/></td>
                                        <td>{ vehicleInspection.RegistrationNumber }</td>
                                        <td className="d-none d-md-table-cell">{ vehicleInspection.DriversName }</td>
                                        <td className="d-none d-lg-table-cell"><ISODate date={vehicleInspection.NextServiceDueDate}/></td>
                                        <td className="align-center">
                                            <Actions
                                                edit={{ action: () => navigate(`/VehicleInspections/${vehicleInspection.Id}`, {state: { action: "edit" }}), title: "Edit Vehicle Inspection" }}
                                                view={{ action: () => navigate(`/VehicleInspections/${vehicleInspection.Id}`, {state: { action: "view" }}), title: "View Vehicle Inspection" }}
                                            />
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Panel>
    </PageContainer>
}