import { Col } from 'react-bootstrap';
import DateField from '../../../Core/Forms/DateField';
import UserRoleSelect from './UserRoleSelect';
import { Roles } from '../../../../Utilities/Enums';

export default function AssignToTeamLeader({value, date, isJobSheet, disabled}) {    

    return (
        <>
            <Col className="col-12 col-xxl-6 mb-3 mb-xxl-0">
                <UserRoleSelect label="Assign To Team Leader" name="TeamLeaderId" value={value} disabled={isJobSheet || disabled} role={Roles.TeamLeader}/>
            </Col>
            {!isJobSheet &&
                <Col className="col-12 col-xxl-6">
                    <DateField label={"Date"} name="Date" value={date && date} disabled={disabled}/>
                </Col>
            }
        </>
    )
}